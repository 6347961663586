.bg-dark
{
    .formContainer
    {
        hr
        {
            border-color: #a0afb9;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
            margin-left: -20px;
            width: 2000px;
        }

        label
        {
            margin-bottom: 0;
            font-size: 15px;
            color: #fff;
            font-weight: 700;
        }

        .label
        {
            font-size: 14px;
            font-weight: 400;
            color: #fff;
        }

        h5.form-group
        {
            font-size: 0.925rem;
            font-weight: 700;
            color: #fff;
        }

        .postTitle
        {
            .title
            {
                width: 90%;
            }
        }

        .accordion
        {
            > .card
            {
                border-radius: 0.4rem;

                > button.btn
                {
                    text-align: left;
                    justify-content: left;
                    border: 0;
                    color: #fff;
                    border-bottom: 1px solid $border-color;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    &:hover
                    {
                        text-decoration: none;
                    }

                    span:hover
                    {
                        text-decoration: none;
                    }

                    div.close
                    {
                        z-index: 999;
                    }
                }
            }

            .card-header
            {
                border: 0;
                padding-left: 0.5rem;
                width: 100%;
            }
        }

        .replyAccordion
        {
            .card
            {
                background-color: #292d3a;
            }

            label
            {
                margin-bottom: 1rem;

                &.dzu-inputLabel
                {
                    margin-bottom: 0;
                }
            }

            .subtitle
            {
                font-weight: 900;
                margin-bottom: 20px;
            }

            .form-group
            {
                padding: 0 15px;
            }
        }

        .templateContainer
        {
            background-color: #222635;
            padding: 15px;
            border-radius: 0.4rem;
            margin: 0 15px;
            margin-bottom: 20px;
            overflow-x: hidden;

            &.replied, &.noQuota
            {
                margin: 0;
                margin-bottom: 20px;
            }

            &.fallback
            {
                background-color: #353949;
                /*padding-left:0;
                padding-right:0;*/
                margin-left: 0;
                margin-right: 0;
            }

            .accordion
            {
                padding:0 15px;
            }

        }

        .dzu-dropzone
        {
            width: 50%;
            min-height: 100px;
            border: 2px dashed #d9d9d9;
            margin: 0;
            margin-bottom: 15px;
            overflow: hidden;

            .dzu-previewImage
            {
                max-height: 200px;
                max-width: 250px;
            }

            .dzu-previewButton
            {
                filter: invert(1);
            }

            .dzu-previewFileName
            {
                color: #fff;
            }
        }

        .rdtPicker
        {
            background-color: #292d3a;
            border: 1px solid #292d3a;
        }
    }
}