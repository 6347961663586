@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #ffffff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #448bff;
  --secondary: #535c78;
  --success: #31c971;
  --info: #14bae4;
  --warning: #f4c414;
  --danger: #f54394;
  --light: #f5f5f6;
  --dark: #222635;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(19, 24, 44, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  color: #212529;
  text-align: left;
  background-color: #f9f9fa; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #448bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #005ef7;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #99a0ac;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.75rem; }

h3, .h3 {
  font-size: 1.53125rem; }

h4, .h4 {
  font-size: 1.3125rem; }

h5, .h5 {
  font-size: 1.09375rem; }

h6, .h6 {
  font-size: 0.875rem; }

.lead {
  font-size: 1.09375rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(19, 24, 44, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.09375rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f9f9fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #ffffff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(160, 175, 185, 0.15); }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(160, 175, 185, 0.15); }
  .table tbody + tbody {
    border-top: 2px solid rgba(160, 175, 185, 0.15); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(160, 175, 185, 0.15); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(160, 175, 185, 0.15); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(19, 24, 44, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(19, 24, 44, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #cbdfff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #9ec3ff; }

.table-hover .table-primary:hover {
  background-color: #b2cfff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #b2cfff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #cfd1d9; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #a6aab9; }

.table-hover .table-secondary:hover {
  background-color: #c1c3ce; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c1c3ce; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c5f0d7; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #94e3b5; }

.table-hover .table-success:hover {
  background-color: #b1ebc9; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b1ebc9; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bdecf7; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #85dbf1; }

.table-hover .table-info:hover {
  background-color: #a6e5f4; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #a6e5f4; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fceebd; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #f9e085; }

.table-hover .table-warning:hover {
  background-color: #fbe8a5; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fbe8a5; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fccae1; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #fa9dc7; }

.table-hover .table-danger:hover {
  background-color: #fbb2d3; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #fbb2d3; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfc; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafa; }

.table-hover .table-light:hover {
  background-color: #efefef; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #efefef; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c1c2c6; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8c8e96; }

.table-hover .table-dark:hover {
  background-color: #b4b5ba; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b4b5ba; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(19, 24, 44, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(11, 14, 26, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(11, 14, 26, 0.075); }

.table .thead-dark th {
  color: #ffffff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(160, 175, 185, 0.15); }

.table-dark {
  color: #ffffff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #ffffff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.42857em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  color: #495057;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      -webkit-transition: none;
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #ffffff;
    border-color: #c4daff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::-ms-input-placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #ffffff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.42857; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 2.5rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.825rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 0.875rem;
  line-height: 1.42857;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 2.5rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #99a0ac; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #31c971; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.825rem;
  line-height: 1.42857;
  color: #ffffff;
  background-color: rgba(49, 201, 113, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #31c971;
  padding-right: calc(1.42857em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2331c971' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35714em + 0.1875rem) center;
  background-size: calc(0.71429em + 0.375rem) calc(0.71429em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #31c971;
    box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.42857em + 0.75rem);
  background-position: top calc(0.35714em + 0.1875rem) right calc(0.35714em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #31c971;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2331c971' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.71429em + 0.375rem) calc(0.71429em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #31c971;
    box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #31c971; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #31c971; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #31c971; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #57d68c;
  background-color: #57d68c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #31c971; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #31c971; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #31c971;
  box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f54394; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.825rem;
  line-height: 1.42857;
  color: #ffffff;
  background-color: rgba(245, 67, 148, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f54394;
  padding-right: calc(1.42857em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f54394' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f54394' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.35714em + 0.1875rem) center;
  background-size: calc(0.71429em + 0.375rem) calc(0.71429em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f54394;
    box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.42857em + 0.75rem);
  background-position: top calc(0.35714em + 0.1875rem) right calc(0.35714em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f54394;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f54394' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f54394' stroke='none'/%3e%3c/svg%3e") #ffffff no-repeat center right 1.75rem/calc(0.71429em + 0.375rem) calc(0.71429em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f54394;
    box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f54394; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f54394; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f54394; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f873b0;
  background-color: #f873b0; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f54394; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f54394; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f54394;
  box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.42857;
  border-radius: 0.25rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      -webkit-transition: none;
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #ffffff;
  background-color: #448bff;
  border-color: #448bff; }
  .btn-primary:hover {
    color: #ffffff;
    background-color: #1e73ff;
    border-color: #116bff; }
  .btn-primary:focus, .btn-primary.focus {
    color: #ffffff;
    background-color: #1e73ff;
    border-color: #116bff;
    box-shadow: 0 0 0 0.2rem rgba(96, 156, 255, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #ffffff;
    background-color: #448bff;
    border-color: #448bff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #116bff;
    border-color: #0463ff; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(96, 156, 255, 0.5); }

.btn-secondary {
  color: #ffffff;
  background-color: #535c78;
  border-color: #535c78; }
  .btn-secondary:hover {
    color: #ffffff;
    background-color: #434b61;
    border-color: #3e455a; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #ffffff;
    background-color: #434b61;
    border-color: #3e455a;
    box-shadow: 0 0 0 0.2rem rgba(109, 116, 140, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #ffffff;
    background-color: #535c78;
    border-color: #535c78; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #3e455a;
    border-color: #393f52; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(109, 116, 140, 0.5); }

.btn-success {
  color: #ffffff;
  background-color: #31c971;
  border-color: #31c971; }
  .btn-success:hover {
    color: #ffffff;
    background-color: #2aaa60;
    border-color: #27a05a; }
  .btn-success:focus, .btn-success.focus {
    color: #ffffff;
    background-color: #2aaa60;
    border-color: #27a05a;
    box-shadow: 0 0 0 0.2rem rgba(80, 209, 134, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #ffffff;
    background-color: #31c971;
    border-color: #31c971; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #ffffff;
    background-color: #27a05a;
    border-color: #259654; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(80, 209, 134, 0.5); }

.btn-info {
  color: #ffffff;
  background-color: #14bae4;
  border-color: #14bae4; }
  .btn-info:hover {
    color: #ffffff;
    background-color: #119dc1;
    border-color: #1094b5; }
  .btn-info:focus, .btn-info.focus {
    color: #ffffff;
    background-color: #119dc1;
    border-color: #1094b5;
    box-shadow: 0 0 0 0.2rem rgba(55, 196, 232, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #ffffff;
    background-color: #14bae4;
    border-color: #14bae4; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #ffffff;
    background-color: #1094b5;
    border-color: #0f8aa9; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 196, 232, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #f4c414;
  border-color: #f4c414; }
  .btn-warning:hover {
    color: #212529;
    background-color: #d8ac0a;
    border-color: #cba20a; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #d8ac0a;
    border-color: #cba20a;
    box-shadow: 0 0 0 0.2rem rgba(212, 172, 23, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #f4c414;
    border-color: #f4c414; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #cba20a;
    border-color: #bf9809; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(212, 172, 23, 0.5); }

.btn-danger {
  color: #ffffff;
  background-color: #f54394;
  border-color: #f54394; }
  .btn-danger:hover {
    color: #ffffff;
    background-color: #f31f7f;
    border-color: #f21378; }
  .btn-danger:focus, .btn-danger.focus {
    color: #ffffff;
    background-color: #f31f7f;
    border-color: #f21378;
    box-shadow: 0 0 0 0.2rem rgba(247, 95, 164, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #ffffff;
    background-color: #f54394;
    border-color: #f54394; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #f21378;
    border-color: #ec0d72; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 95, 164, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f5f5f6;
  border-color: #f5f5f6; }
  .btn-light:hover {
    color: #212529;
    background-color: #e1e1e4;
    border-color: #dadade; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e1e1e4;
    border-color: #dadade;
    box-shadow: 0 0 0 0.2rem rgba(213, 214, 215, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f5f5f6;
    border-color: #f5f5f6; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dadade;
    border-color: #d3d3d8; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(213, 214, 215, 0.5); }

.btn-dark {
  color: #ffffff;
  background-color: #222635;
  border-color: #222635; }
  .btn-dark:hover {
    color: #ffffff;
    background-color: #13151e;
    border-color: #0e1016; }
  .btn-dark:focus, .btn-dark.focus {
    color: #ffffff;
    background-color: #13151e;
    border-color: #0e1016;
    box-shadow: 0 0 0 0.2rem rgba(67, 71, 83, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #ffffff;
    background-color: #222635;
    border-color: #222635; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #0e1016;
    border-color: #090a0e; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(67, 71, 83, 0.5); }

.btn-outline-primary {
  color: #448bff;
  border-color: #448bff; }
  .btn-outline-primary:hover {
    color: #ffffff;
    background-color: #448bff;
    border-color: #448bff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #448bff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #ffffff;
    background-color: #448bff;
    border-color: #448bff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.5); }

.btn-outline-secondary {
  color: #535c78;
  border-color: #535c78; }
  .btn-outline-secondary:hover {
    color: #ffffff;
    background-color: #535c78;
    border-color: #535c78; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(83, 92, 120, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #535c78;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #ffffff;
    background-color: #535c78;
    border-color: #535c78; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(83, 92, 120, 0.5); }

.btn-outline-success {
  color: #31c971;
  border-color: #31c971; }
  .btn-outline-success:hover {
    color: #ffffff;
    background-color: #31c971;
    border-color: #31c971; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #31c971;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #ffffff;
    background-color: #31c971;
    border-color: #31c971; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.5); }

.btn-outline-info {
  color: #14bae4;
  border-color: #14bae4; }
  .btn-outline-info:hover {
    color: #ffffff;
    background-color: #14bae4;
    border-color: #14bae4; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(20, 186, 228, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #14bae4;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #ffffff;
    background-color: #14bae4;
    border-color: #14bae4; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(20, 186, 228, 0.5); }

.btn-outline-warning {
  color: #f4c414;
  border-color: #f4c414; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #f4c414;
    border-color: #f4c414; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(244, 196, 20, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #f4c414;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #f4c414;
    border-color: #f4c414; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(244, 196, 20, 0.5); }

.btn-outline-danger {
  color: #f54394;
  border-color: #f54394; }
  .btn-outline-danger:hover {
    color: #ffffff;
    background-color: #f54394;
    border-color: #f54394; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f54394;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #ffffff;
    background-color: #f54394;
    border-color: #f54394; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.5); }

.btn-outline-light {
  color: #f5f5f6;
  border-color: #f5f5f6; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f5f5f6;
    border-color: #f5f5f6; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 246, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f5f5f6;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f5f5f6;
    border-color: #f5f5f6; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 245, 246, 0.5); }

.btn-outline-dark {
  color: #222635;
  border-color: #222635; }
  .btn-outline-dark:hover {
    color: #ffffff;
    background-color: #222635;
    border-color: #222635; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(34, 38, 53, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #222635;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #ffffff;
    background-color: #222635;
    border-color: #222635; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(34, 38, 53, 0.5); }

.btn-link {
  font-weight: 400;
  color: #448bff;
  text-decoration: none; }
  .btn-link:hover {
    color: #005ef7;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 2.5rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      -webkit-transition: none;
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      -webkit-transition: none;
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(19, 24, 44, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #ffffff;
    text-decoration: none;
    background-color: #448bff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.825rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 2.5rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.25rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.125rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #ffffff;
    border-color: #448bff;
    background-color: #448bff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #c4daff; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #ffffff;
    background-color: #f7faff;
    border-color: #f7faff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.125rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #ffffff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.125rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23ffffff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #448bff;
  background-color: #448bff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23ffffff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 139, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(68, 139, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(68, 139, 255, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.125rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        -webkit-transition: none;
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #ffffff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(68, 139, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.42857em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.42857;
  color: #495057;
  vertical-align: middle;
  background: #ffffff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #c4daff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #ffffff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.825rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 2.5rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.42857em + 0.75rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.42857em + 0.75rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #c4daff;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.42857em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.42857;
  color: #495057;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.42857em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.42857;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f9f9fa, 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f9f9fa, 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f9f9fa, 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #448bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #f7faff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #448bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -moz-appearance: none;
         appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #f7faff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #448bff;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #f7faff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      -webkit-transition: none;
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #f9f9fa;
    border-color: #dee2e6 #dee2e6 #f9f9fa; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #ffffff;
  background-color: #448bff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: -0.125rem;
  padding-bottom: -0.125rem;
  margin-right: 1rem;
  font-size: 2.5rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 2.5rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(19, 24, 44, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(19, 24, 44, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(19, 24, 44, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(19, 24, 44, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(19, 24, 44, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(19, 24, 44, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(19, 24, 44, 0.5);
  border-color: rgba(19, 24, 44, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(19, 24, 44, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(19, 24, 44, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(19, 24, 44, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(19, 24, 44, 0.9); }

.navbar-dark .navbar-brand {
  color: #ffffff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #ffffff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #ffffff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #ffffff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #ffffff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid rgba(19, 24, 44, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(19, 24, 44, 0.03);
  border-bottom: 1px solid rgba(19, 24, 44, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(19, 24, 44, 0.03);
  border-top: 1px solid rgba(19, 24, 44, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
       -moz-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
       -moz-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #6c757d; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #448bff;
  background-color: #ffffff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #005ef7;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #ffffff;
  background-color: #448bff;
  border-color: #448bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #ffffff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 2.5rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.825rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.15rem;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      -webkit-transition: none;
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #ffffff;
  background-color: #448bff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #ffffff;
    background-color: #116bff; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.5); }

.badge-secondary {
  color: #ffffff;
  background-color: #535c78; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #ffffff;
    background-color: #3e455a; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(83, 92, 120, 0.5); }

.badge-success {
  color: #ffffff;
  background-color: #31c971; }
  a.badge-success:hover, a.badge-success:focus {
    color: #ffffff;
    background-color: #27a05a; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(49, 201, 113, 0.5); }

.badge-info {
  color: #ffffff;
  background-color: #14bae4; }
  a.badge-info:hover, a.badge-info:focus {
    color: #ffffff;
    background-color: #1094b5; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(20, 186, 228, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #f4c414; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cba20a; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(244, 196, 20, 0.5); }

.badge-danger {
  color: #ffffff;
  background-color: #f54394; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #ffffff;
    background-color: #f21378; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 67, 148, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f5f5f6; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dadade; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 245, 246, 0.5); }

.badge-dark {
  color: #ffffff;
  background-color: #222635; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #ffffff;
    background-color: #0e1016; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(34, 38, 53, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.8125rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #2c549a;
  background-color: #dae8ff;
  border-color: #cbdfff; }
  .alert-primary hr {
    border-top-color: #b2cfff; }
  .alert-primary .alert-link {
    color: #213e72; }

.alert-secondary {
  color: #343b54;
  background-color: #dddee4;
  border-color: #cfd1d9; }
  .alert-secondary hr {
    border-top-color: #c1c3ce; }
  .alert-secondary .alert-link {
    color: #212535; }

.alert-success {
  color: #237450;
  background-color: #d6f4e3;
  border-color: #c5f0d7; }
  .alert-success hr {
    border-top-color: #b1ebc9; }
  .alert-success .alert-link {
    color: #174d35; }

.alert-info {
  color: #146c8c;
  background-color: #d0f1fa;
  border-color: #bdecf7; }
  .alert-info hr {
    border-top-color: #a6e5f4; }
  .alert-info .alert-link {
    color: #0e4a5f; }

.alert-warning {
  color: #887120;
  background-color: #fdf3d0;
  border-color: #fceebd; }
  .alert-warning hr {
    border-top-color: #fbe8a5; }
  .alert-warning .alert-link {
    color: #5f4f16; }

.alert-danger {
  color: #892e62;
  background-color: #fdd9ea;
  border-color: #fccae1; }
  .alert-danger hr {
    border-top-color: #fbb2d3; }
  .alert-danger .alert-link {
    color: #632147; }

.alert-light {
  color: #898b95;
  background-color: #fdfdfd;
  border-color: #fcfcfc; }
  .alert-light hr {
    border-top-color: #efefef; }
  .alert-light .alert-link {
    color: #6f717c; }

.alert-dark {
  color: #1b1f31;
  background-color: #d3d4d7;
  border-color: #c1c2c6; }
  .alert-dark hr {
    border-top-color: #b4b5ba; }
  .alert-dark .alert-link {
    color: #090a10; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  background-color: #448bff;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      -webkit-transition: none;
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #ffffff;
  border: 1px solid rgba(19, 24, 44, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #ffffff; }
  .list-group-item.active {
    z-index: 2;
    color: #ffffff;
    background-color: #448bff;
    border-color: #448bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-primary {
  color: #2c549a;
  background-color: #cbdfff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #2c549a;
    background-color: #b2cfff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #ffffff;
    background-color: #2c549a;
    border-color: #2c549a; }

.list-group-item-secondary {
  color: #343b54;
  background-color: #cfd1d9; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #343b54;
    background-color: #c1c3ce; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #ffffff;
    background-color: #343b54;
    border-color: #343b54; }

.list-group-item-success {
  color: #237450;
  background-color: #c5f0d7; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #237450;
    background-color: #b1ebc9; }
  .list-group-item-success.list-group-item-action.active {
    color: #ffffff;
    background-color: #237450;
    border-color: #237450; }

.list-group-item-info {
  color: #146c8c;
  background-color: #bdecf7; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #146c8c;
    background-color: #a6e5f4; }
  .list-group-item-info.list-group-item-action.active {
    color: #ffffff;
    background-color: #146c8c;
    border-color: #146c8c; }

.list-group-item-warning {
  color: #887120;
  background-color: #fceebd; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #887120;
    background-color: #fbe8a5; }
  .list-group-item-warning.list-group-item-action.active {
    color: #ffffff;
    background-color: #887120;
    border-color: #887120; }

.list-group-item-danger {
  color: #892e62;
  background-color: #fccae1; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #892e62;
    background-color: #fbb2d3; }
  .list-group-item-danger.list-group-item-action.active {
    color: #ffffff;
    background-color: #892e62;
    border-color: #892e62; }

.list-group-item-light {
  color: #898b95;
  background-color: #fcfcfc; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #898b95;
    background-color: #efefef; }
  .list-group-item-light.list-group-item-action.active {
    color: #ffffff;
    background-color: #898b95;
    border-color: #898b95; }

.list-group-item-dark {
  color: #1b1f31;
  background-color: #c1c2c6; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1f31;
    background-color: #b4b5ba; }
  .list-group-item-dark.list-group-item-action.active {
    color: #ffffff;
    background-color: #1b1f31;
    border-color: #1b1f31; }

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #13182c;
  text-shadow: 0 1px 0 #ffffff;
  opacity: .5; }
  .close:hover {
    color: #13182c;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(19, 24, 44, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(19, 24, 44, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #13182c; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(160, 175, 185, 0.15);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.42857; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(160, 175, 185, 0.15);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.825rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #13182c; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #13182c; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #13182c; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #13182c; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #ffffff;
  text-align: center;
  background-color: #13182c;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.825rem;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid rgba(19, 24, 44, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(19, 24, 44, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #ffffff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(19, 24, 44, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #ffffff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(19, 24, 44, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #ffffff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(19, 24, 44, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #ffffff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      -webkit-transition: none;
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      -webkit-transition: none;
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #ffffff;
  text-align: center;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      -webkit-transition: none;
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #ffffff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        -webkit-transition: none;
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #ffffff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #448bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #116bff !important; }

.bg-secondary {
  background-color: #535c78 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #3e455a !important; }

.bg-success {
  background-color: #31c971 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #27a05a !important; }

.bg-info {
  background-color: #14bae4 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1094b5 !important; }

.bg-warning {
  background-color: #f4c414 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cba20a !important; }

.bg-danger {
  background-color: #f54394 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #f21378 !important; }

.bg-light {
  background-color: #f5f5f6 !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dadade !important; }

.bg-dark {
  background-color: #222635 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #0e1016 !important; }

.bg-white {
  background-color: #ffffff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(160, 175, 185, 0.15) !important; }

.border-top {
  border-top: 1px solid rgba(160, 175, 185, 0.15) !important; }

.border-right {
  border-right: 1px solid rgba(160, 175, 185, 0.15) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(160, 175, 185, 0.15) !important; }

.border-left {
  border-left: 1px solid rgba(160, 175, 185, 0.15) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #448bff !important; }

.border-secondary {
  border-color: #535c78 !important; }

.border-success {
  border-color: #31c971 !important; }

.border-info {
  border-color: #14bae4 !important; }

.border-warning {
  border-color: #f4c414 !important; }

.border-danger {
  border-color: #f54394 !important; }

.border-light {
  border-color: #f5f5f6 !important; }

.border-dark {
  border-color: #222635 !important; }

.border-white {
  border-color: #ffffff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(19, 24, 44, 0.075) !important; }

.shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(19, 24, 44, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #ffffff !important; }

.text-primary {
  color: #448bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #005ef7 !important; }

.text-secondary {
  color: #535c78 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #34394b !important; }

.text-success {
  color: #31c971 !important; }

a.text-success:hover, a.text-success:focus {
  color: #228b4e !important; }

.text-info {
  color: #14bae4 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0e819e !important; }

.text-warning {
  color: #f4c414 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38f08 !important; }

.text-danger {
  color: #f54394 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #e00c6c !important; }

.text-light {
  color: #f5f5f6 !important; }

a.text-light:hover, a.text-light:focus {
  color: #cdcdd2 !important; }

.text-dark {
  color: #222635 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #040506 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #99a0ac !important; }

.text-black-50 {
  color: rgba(19, 24, 44, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #13182c; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #ffffff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(160, 175, 185, 0.15); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(160, 175, 185, 0.15); } }

/*------------------------------------------------------------------
[Table of contents]
1. Bootstrap Extention
2. UI components
3. Utilities
4. Material Design style
-------------------------------------------------------------------*/
/*---------------------------
1. Bootstrap Extension 
---------------------------*/
/*
Base 
*/
html {
  background: #f9f9fa; }

body {
  width: 100%;
  min-height: 100vh;
  color: #5e676f;
  background: inherit; }

*:focus {
  outline: 0 !important; }

a {
  text-decoration: none;
  cursor: pointer;
  outline: 0; }

a:hover,
a:focus {
  text-decoration: none; }

button {
  color: inherit; }

pre {
  color: #5e676f;
  background-color: #f5f5f6;
  border-color: rgba(160, 175, 185, 0.15); }

hr {
  border-color: rgba(160, 175, 185, 0.15); }

blockquote {
  border-color: rgba(160, 175, 185, 0.15); }

small {
  font-size: 0.825rem; }

sup {
  vertical-align: text-top;
  top: -.25em; }

b,
strong {
  font-weight: 500; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #222635; }

/*
Alert
*/
.close {
  color: inherit;
  text-shadow: none; }
  .close:hover, .close:focus {
    color: inherit; }

/*
badge 
*/
.badge-up {
  padding: 0 0.25rem;
  position: absolute;
  right: 0;
  -webkit-transform: translate(0%, -50%);
          transform: translate(0%, -50%);
  background-clip: padding-box;
  min-width: 1rem;
  min-height: 1rem;
  line-height: 1rem; }

.badge-outline {
  border: 1px solid currentColor;
  border-radius: 0;
  background-color: transparent;
  font-weight: 100; }

.badge-circle {
  padding: 0 !important;
  width: 0.625rem;
  height: 0.625rem;
  border: 2px solid;
  border-radius: 50%; }
  .badge-circle:empty {
    display: inline-block; }
  .badge-circle.xs {
    width: 0;
    height: 0;
    border-width: 3px;
    vertical-align: 2px; }
  .badge-circle.sm {
    width: 0.5rem;
    height: 0.5rem;
    vertical-align: 1px; }
  .badge-circle.md {
    width: 1rem;
    height: 1rem;
    vertical-align: -3px; }
  .badge-circle.lg {
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: -7px; }

.badge-sm {
  line-height: 0.8; }

.badge-md {
  padding: 0.5em 0.75em; }

.badge-lg {
  padding: 0.75em 1em; }

/*
Buttons 
*/
.btn {
  font-weight: 500;
  display: -ms-inline-flex;
  display: inline-flex;
  align-items: center;
  justify-content: center; }
  .btn > i.float-left, .btn > i.float-right {
    line-height: inherit;
    margin: 0; }
  .btn > i svg {
    max-width: 16px;
    max-height: 16px;
    vertical-align: middle; }
  .btn.loading {
    text-indent: -9999rem; }
  .btn:hover, .btn:active {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1); }

.btn-md {
  padding: 0.75rem 1.25rem; }

.btn-xs {
  font-size: 0.75rem;
  padding: 0.125rem 0.5rem; }

.btn-white {
  color: #5e676f;
  background-color: #fff;
  border-color: transparent;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); }
  .input-group .btn-white {
    border-color: rgba(160, 175, 185, 0.15); }

.btn-rounded {
  border-radius: 4rem;
  padding-left: 1rem;
  padding-right: 1rem; }
  .btn-rounded.btn-xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }

.btn-icon {
  width: 2.125rem;
  height: 2.125rem;
  padding: 0 !important; }
  .btn-icon.btn-sm, .btn-group-sm > .btn-icon.btn {
    width: 1.875rem;
    height: 1.875rem; }
  .btn-icon.btn-md {
    width: 2.5rem;
    height: 2.5rem; }
  .btn-icon.btn-lg, .btn-group-lg > .btn-icon.btn {
    width: 3.375rem;
    height: 3.375rem; }

.btn-line {
  position: relative; }
  .btn-line:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    height: 1px;
    width: 100%;
    background: #000;
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    transition: transform 0.8s cubic-bezier(0.9, 0, 0.1, 1), -webkit-transform 0.8s cubic-bezier(0.9, 0, 0.1, 1);
    -webkit-transform-origin: right center;
            transform-origin: right center;
    -webkit-transform: scaleX(0);
            transform: scaleX(0); }
  .btn-line:hover:after, .btn-line:active:after {
    -webkit-transform-origin: left center;
            transform-origin: left center;
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }

.btn-wave {
  overflow: hidden;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }
  .btn-wave:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    -webkit-transform: scale(10, 10);
            transform: scale(10, 10);
    opacity: 0;
    -webkit-transition: opacity 1.5s, -webkit-transform .5s;
    transition: opacity 1.5s, -webkit-transform .5s;
    transition: transform .5s, opacity 1.5s;
    transition: transform .5s, opacity 1.5s, -webkit-transform .5s; }
  .btn-wave:active:before {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: .1;
    -webkit-transition: 0s;
    transition: 0s; }

.btn-raised {
  -webkit-transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26); }
  .btn-raised:not([disabled]):hover, .btn-raised:not([disabled]):focus, .btn-raised:not([disabled]):active {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    -webkit-transform: translate3d(0, -1px, 0);
            transform: translate3d(0, -1px, 0); }

.btn-group-toggle {
  display: inline-flex;
  padding: 0.25rem 0.125rem;
  border: 1px solid rgba(160, 175, 185, 0.15);
  border-radius: 0.25rem; }
  .btn-group-toggle .btn {
    font-size: 0.75rem;
    padding: 0.125rem 0.35rem;
    margin: 0 0.125rem !important;
    border-radius: inherit !important; }
    .btn-group-toggle .btn.active {
      color: #ffffff !important;
      background-color: #448bff; }

.btn-play {
  position: relative; }
  .btn-play.bg--white {
    color: #6c7781 !important; }
  .btn-play:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-4px, -50%);
            transform: translate(-4px, -50%);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    border: 6px solid transparent;
    border-left-color: currentColor;
    border-left-width: 10px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s; }
  .btn-play.active:before {
    width: 10px;
    height: 10px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 3px;
    border-right-width: 3px;
    border-right-color: currentColor;
    -webkit-transform: translate(-50%, -50%) rotate(180.1deg);
            transform: translate(-50%, -50%) rotate(180.1deg); }
    .is-buffering .btn-play.active:before {
      border: 2px solid;
      border-left-color: transparent;
      border-radius: 100%;
      margin-left: -5px;
      margin-top: -5px;
      -webkit-animation: loading 1s linear infinite;
              animation: loading 1s linear infinite; }

/*
Card
*/
.card,
.block {
  background: #fff;
  border-width: 0;
  border-radius: 0.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  margin-bottom: 1.5rem; }

.card-header,
.card-footer {
  background-color: transparent;
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box; }

.card-body p:last-child {
  margin-bottom: 0; }

.card-hide-body .card-body {
  display: none; }

.card-title {
  line-height: 1.5; }

.block-fold:before, .block-fold:after,
.card-fold:before,
.card-fold:after {
  content: '';
  display: block;
  background-color: inherit;
  box-shadow: inherit;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  width: 90%;
  left: 5%;
  height: 0.35rem;
  bottom: -0.35rem;
  opacity: 0.6;
  -webkit-transition: all .1s;
  transition: all .1s; }

.block-fold:before,
.card-fold:before {
  width: 80%;
  left: 10%;
  bottom: -0.7rem;
  opacity: 0.3; }

.block-fold:hover:before, .block-fold:active:before,
.card-fold:hover:before,
.card-fold:active:before {
  opacity: 0.5; }

.block-fold:hover:after, .block-fold:active:after,
.card-fold:hover:after,
.card-fold:active:after {
  opacity: 0.8; }

/*
Carousel 
*/
.carousel-indicators li {
  width: 5px;
  height: 5px;
  border-radius: 5px;
  -webkit-transition: all .3s;
  transition: all .3s; }

.carousel-indicators li.active {
  width: 20px; }

.carousel-fade .carousel-item {
  -webkit-transition: opacity 1s;
  transition: opacity 1s; }

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  opacity: 1; }

.carousel-fade .carousel-item-next,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-prev,
.carousel-fade .active.carousel-item-left {
  opacity: 0; }

/*
Dropdown menu 
*/
.dropdown-menu {
  border-radius: 3px;
  border-color: rgba(160, 175, 185, 0.15);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  color: #5e676f; }
  .dropdown-menu > li:not(.dropdown-item) > a {
    display: block;
    padding: 3px 20px; }
    .dropdown-menu > li:not(.dropdown-item) > a:hover, .dropdown-menu > li:not(.dropdown-item) > a:focus {
      background-color: rgba(135, 150, 165, 0.1); }
  .dropdown-menu > .divider {
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: rgba(160, 175, 185, 0.15); }
  .dropdown-menu .active {
    background-color: rgba(135, 150, 165, 0.1); }

.dropdown-menu-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%); }

.dropdown-item {
  color: inherit; }
  .dropdown-item a {
    color: inherit; }
  .dropdown-item i,
  .dropdown-item svg {
    margin-left: -0.5rem;
    margin-right: .5rem;
    text-align: center; }

.dropdown-item,
.dropdown-item.active {
  color: inherit; }
  .dropdown-item:hover, .dropdown-item:focus,
  .dropdown-item.active:hover,
  .dropdown-item.active:focus {
    color: inherit;
    background-color: rgba(135, 150, 165, 0.1); }

.dropdown-header {
  color: inherit;
  opacity: 0.5; }

.dropdown-toggle {
  white-space: nowrap !important; }

.dropdown-divider {
  border-color: rgba(160, 175, 185, 0.15); }

.dropdown-submenu {
  position: relative; }
  .dropdown-submenu:hover > .dropdown-menu, .dropdown-submenu:focus > .dropdown-menu {
    display: block; }
  .dropdown-submenu .dropdown-menu {
    left: 100%;
    top: 0;
    margin-top: -8px;
    margin-left: -1px; }
    .dropdown-submenu .dropdown-menu.pull-left {
      right: 100%;
      left: auto; }
  .dropup .dropdown-submenu > .dropdown-menu {
    top: auto;
    bottom: 0;
    margin-bottom: -6px; }

.popover {
  color: #5e676f; }

/*
Form 
*/
.form-control {
  border-color: rgba(135, 150, 165, 0.15);
  border-width: 1px;
  border-radius: 0.25rem;
  color: inherit; }
  .form-control:focus {
    color: inherit;
    box-shadow: 0 0 0 0.2rem rgba(68, 139, 255, 0.25); }
  .form-control:not(.is-valid):not(.is-invalid):focus {
    border-color: #448bff; }

.form-control-theme {
  border-width: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); }

.col-form-label {
  color: #99a0ac; }

.form-check-input {
  margin-top: 0.2rem; }

.custom-file-control,
.custom-file-control:before,
.custom-select {
  border-color: rgba(135, 150, 165, 0.15); }

.custom-select,
.custom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.custom-control-label {
  line-height: 1.75; }

.form-control::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control::-moz-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control:-ms-input-placeholder {
  color: inherit;
  opacity: 0.3; }

.form-control-spin {
  position: absolute;
  z-index: 2;
  right: 10px;
  top: 50%;
  margin-top: -7px; }

.custom-file-label,
.custom-file-label:after,
.input-group-text {
  border-color: rgba(135, 150, 165, 0.15) !important;
  background-color: transparent;
  color: inherit; }

.input-group-text,
.custom-file-label:after {
  background-color: rgba(160, 175, 185, 0.05); }

.form-group-stack .form-control {
  margin-top: -1px;
  position: relative;
  border-radius: 0; }
  .form-group-stack .form-control:focus, .form-group-stack .form-control:active {
    z-index: 1; }

.input-group-sm > .form-control {
  min-height: 1.95rem; }

.active-checked {
  opacity: 0; }

input:checked + .active-checked {
  opacity: 1; }

/*
List Group 
*/
.list-group {
  border-radius: 3px;
  background: #fff;
  padding-right: 0; }
  .list-group.no-radius .list-group-item {
    border-radius: 0 !important; }
  .list-group.no-borders .list-group-item {
    border: none; }
  .list-group.no-border .list-group-item {
    border-width: 1px 0; }
  .list-group.box .list-group-item, .list-group.no-border .list-group-item {
    border-width: 1px 0; }
    .list-group.box .list-group-item:first-child, .list-group.no-border .list-group-item:first-child {
      border-top-width: 0; }
    .list-group.box .list-group-item:last-child, .list-group.no-border .list-group-item:last-child {
      border-bottom-width: 0; }
  .list-group.no-bg .list-group-item {
    background-color: transparent; }
  .list-group.list-group-gap {
    background: transparent; }
  .list-group.box .list-group-item {
    border-left-width: 0;
    border-right-width: 0; }

.list-group-item {
  border-color: rgba(160, 175, 185, 0.05);
  background: transparent;
  padding: 12px 16px;
  background-clip: padding-box; }
  .list-group-item.media {
    margin-top: 0; }
  .list-group-item.box {
    border-width: 0; }
  .list-group-item.active, .list-group-item.active:hover, .list-group-item.active:focus {
    color: inherit;
    background-color: rgba(135, 150, 165, 0.1);
    border-color: rgba(160, 175, 185, 0.05);
    border-bottom-color: rgba(160, 175, 185, 0.05); }
    .list-group-item.active a, .list-group-item.active:hover a, .list-group-item.active:focus a {
      color: inherit; }
  .list-group-item:first-child {
    border-top-color: rgba(160, 175, 185, 0.15); }
  .list-group-item:last-child {
    border-bottom-color: rgba(160, 175, 185, 0.15); }
  .list-group-alt .list-group-item:nth-child(2n+2) {
    background-color: rgba(135, 150, 165, 0.1); }
  .list-group-alt .list-group-item.active:nth-child(2n+2) {
    background-color: rgba(135, 150, 165, 0.1); }
  .list-group-lg .list-group-item {
    padding: 16px 24px; }
  .list-group-md .list-group-item {
    padding-top: 16px;
    padding-bottom: 16px; }
  .list-group-sm .list-group-item {
    padding: 10px 12px; }
  .list-group-gap .list-group-item {
    margin-bottom: 5px;
    border-radius: 3px; }
    .list-group-gap .list-group-item:first-child {
      border-top-color: rgba(160, 175, 185, 0.05); }
    .list-group-gap .list-group-item:last-child {
      border-bottom-color: rgba(160, 175, 185, 0.05); }

a.list-group-item {
  color: inherit; }
  a.list-group-item:hover, a.list-group-item:focus, a.list-group-item.hover {
    color: inherit;
    background-color: rgba(135, 150, 165, 0.1); }

/*
Modal 
*/
.modal.fade .modal-left,
.modal.fade .modal-right,
.modal.fade .modal-top,
.modal.fade .modal-bottom {
  position: fixed;
  z-index: 1055;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  max-width: 100%; }

.modal.fade .modal-left {
  right: auto !important;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.fade .modal-right {
  left: auto !important;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.fade .modal-top {
  bottom: auto !important;
  -webkit-transform: translate3d(0, -100%, 0);
          transform: translate3d(0, -100%, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.fade .modal-bottom {
  top: auto !important;
  -webkit-transform: translate3d(0, 100%, 0);
          transform: translate3d(0, 100%, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1); }

.modal.fade.show .modal-left,
.modal.fade.show .modal-right,
.modal.fade.show .modal-top,
.modal.fade.show .modal-bottom {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0); }

.modal.fade.inactive {
  bottom: auto;
  overflow: visible; }

.modal-open-aside {
  overflow: visible; }

.modal-header,
.modal-content,
.modal-footer {
  border: none; }

.modal-dialog > * {
  pointer-events: auto; }

.modal-header {
  margin-top: -1px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.modal-footer {
  margin-top: auto;
  border-bottom-left-radius: inherit;
  border-tbottomop-right-radius: inherit; }

.modal .close {
  float: none; }

/*
Modal animation 
*/
.modal .modal-dialog {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0); }

.modal .fade-right {
  -webkit-animation: fadeInLeft 0.5s;
          animation: fadeInLeft 0.5s;
  animation-direction: reverse; }

.modal .fade-left {
  -webkit-animation: fadeInRight 0.5s;
          animation: fadeInRight 0.5s;
  animation-direction: reverse; }

.modal .fade-up {
  -webkit-animation: fadeInDown 0.5s;
          animation: fadeInDown 0.5s;
  animation-direction: reverse; }

.modal .fade-down {
  -webkit-animation: fadeInUp 0.5s;
          animation: fadeInUp 0.5s;
  animation-direction: reverse; }

.modal.show .fade-right {
  -webkit-animation: fadeInRight 0.5s;
          animation: fadeInRight 0.5s; }

.modal.show .fade-left {
  -webkit-animation: fadeInLeft 0.5s;
          animation: fadeInLeft 0.5s; }

.modal.show .fade-up {
  -webkit-animation: fadeInUp 0.5s;
          animation: fadeInUp 0.5s; }

.modal.show .fade-down {
  -webkit-animation: fadeInDown 0.5s;
          animation: fadeInDown 0.5s; }

/*
Nav 
*/
.nav {
  border: inherit; }
  .nav.flex-column .nav {
    flex-direction: column;
    flex-wrap: nowrap; }

.nav-item {
  position: relative;
  border: inherit; }

.nav-link {
  color: inherit;
  border: inherit;
  position: relative; }

.nav-md .nav-link {
  padding: 0.75rem 1.25rem; }

.nav-sm .nav-link {
  padding: 0.25rem 0.75rem; }

.nav-xs .nav-link {
  padding: 0.125rem 0.375rem; }

.nav-xxs .nav-link {
  padding-left: 0;
  padding-right: 0;
  margin: 0 0.5rem; }

.nav-rounded .nav-link {
  border-radius: 2rem; }

.nav-tabs {
  border-bottom-width: 0;
  position: relative;
  z-index: 1; }
  .nav-tabs .nav-link {
    background: transparent !important;
    color: inherit !important; }
    .nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus, .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: rgba(160, 175, 185, 0.15);
      border-bottom-color: transparent; }

.tab-content.tab-alt .tab-pane {
  display: block;
  height: 0;
  overflow: hidden; }
  .tab-content.tab-alt .tab-pane.active {
    height: auto;
    overflow: visible; }

.nav-justified .nav-item {
  display: table-cell;
  width: 1%;
  float: none !important;
  text-align: center; }

.nav-lists .nav-item {
  border-bottom: 1px solid rgba(160, 175, 185, 0.15);
  background-clip: padding-box; }

.breadcrumb {
  background-color: rgba(135, 150, 165, 0.1); }
  .breadcrumb > li + li:before {
    content: '/ '; }

.nav-active-border li > a:before,
.nav-active-border .nav-link:before {
  content: '';
  position: absolute;
  left: 50%;
  right: 50%;
  bottom: 0;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .nav-active-border li > a:before,
    .nav-active-border .nav-link:before {
      -webkit-transition: none;
      transition: none; } }

.nav-active-border li > a.active:before,
.nav-active-border .nav-link.active:before {
  left: 0;
  right: 0;
  border-bottom-color: #448bff; }

.nav-active-border li.active > a:before {
  left: 1rem;
  right: 1rem;
  border-bottom-color: #448bff; }

.nav-active-border.top li.active > a:before,
.nav-active-border.top .nav-link:before {
  bottom: auto;
  top: 0; }

/*
Navbar 
*/
.navbar {
  min-height: 3.75rem;
  padding: 0.75rem 1rem;
  display: flex; }
  .navbar .dropdown-menu-right {
    left: auto;
    right: 0; }
  .navbar svg {
    vertical-align: middle; }
  .navbar .nav-link {
    color: inherit;
    white-space: nowrap; }
  .navbar .nav-active-border .nav-link {
    margin: -0.75rem 0;
    line-height: 2.75rem; }
  .navbar .dropdown-menu {
    margin-top: 0; }
  .bg-white .navbar .nav-item.active {
    color: #448bff; }

.toolbar {
  flex-shrink: 0;
  display: flex;
  flex-wrap: nowrap;
  margin: 0 -0.25rem; }
  .toolbar > * {
    margin: 0 0.25rem; }
  .toolbar.toolbar-mx {
    margin: 0 -0.5rem; }
    .toolbar.toolbar-mx > * {
      margin: 0 0.5rem; }

.nav-title {
  font-size: 1.125rem;
  line-height: 1.2; }

.navbar-brand {
  font-size: 1.125rem;
  line-height: 1;
  display: flex;
  align-items: center; }
  .navbar-brand > span {
    margin: 0 0.5rem;
    font-weight: 600; }
  .navbar-brand img,
  .navbar-brand svg {
    max-height: 2.5rem;
    vertical-align: middle;
    font-weight: 600; }

.navbar-menu {
  flex-direction: row;
  margin-left: auto;
  -ms-align-items: center;
  align-items: center; }

/*
pagination 
*/
.pagination a {
  padding: .5rem .75rem;
  border: 1px solid rgba(160, 175, 185, 0.15); }

.pagination .page-link,
.pagination li a {
  color: inherit !important;
  background-color: transparent !important;
  border-color: rgba(160, 175, 185, 0.15) !important;
  display: block; }
  .pagination .page-link:hover, .pagination .page-link:focus,
  .pagination li a:hover,
  .pagination li a:focus {
    border-color: rgba(160, 175, 185, 0.15);
    background-color: rgba(135, 150, 165, 0.1);
    color: inherit; }

.pagination .active .page-link,
.pagination .active a {
  color: white !important;
  background-color: #448bff !important;
  border-color: #448bff !important; }

.pagination li:first-child a {
  border-top-left-radius: .2rem;
  border-bottom-left-radius: .2rem; }

.pagination li:last-child a {
  border-top-right-radius: .2rem;
  border-bottom-right-radius: .2rem; }

.pagination-sm a {
  padding: .2195rem .5rem; }

.pagination-xs a {
  padding: 0 .35rem;
  line-height: 1.5; }

/*
Popover
*/
.popover {
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); }

.popover-header {
  border-color: rgba(160, 175, 185, 0.05);
  background-color: #fff; }

.popover-body {
  color: #5e676f; }
  .popover-body p:last-child {
    margin: 0; }

.popover .arrow:before {
  opacity: 0.15; }

/*
Progress 
*/
.progress {
  border-radius: .25rem;
  overflow: visible;
  background-color: rgba(135, 150, 165, 0.1); }

.progress-bar {
  height: 100%;
  text-align: center;
  font-size: 0.8em;
  border-radius: .25rem;
  -webkit-transition: width .6s ease;
  transition: width .6s ease; }

/*
Row 
*/
.padding {
  padding: 2rem; }

.row-md {
  margin-left: -0.75rem;
  margin-right: -0.75rem; }
  .row-md [class*="col-"],
  .row-md .col {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .row-md .card,
  .row-md .block {
    margin-bottom: 1.5rem; }

.row-sm {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  .row-sm [class*="col-"],
  .row-sm .col {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .row-sm .card,
  .row-sm .block {
    margin-bottom: 1rem; }

.row-xs {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }
  .row-xs [class*="col-"],
  .row-xs .col {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .row-xs .card,
  .row-xs .block {
    margin-bottom: 0.5rem; }

@media (min-width: 992px) {
  .col-lg-2-4 {
    flex: 0 0 20%;
    max-width: 20%; } }

@media (min-width: 1200px) {
  .col-xl-1-8 {
    flex: 0 0 12.5%;
    max-width: 12.5%; } }

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem; } }

@media (max-width: 767.98px) {
  .padding {
    padding: 1rem; } }

/*
Table 
*/
.table > thead > tr > th {
  border-width: 0; }

.table-condensed thead > tr > th,
.table-condensed tbody > tr > th,
.table-condensed tfoot > tr > th,
.table-condensed thead > tr > td,
.table-condensed tbody > tr > td,
.table-condensed tfoot > tr > td {
  padding: 5px; }

.table-bordered {
  border-color: rgba(160, 175, 185, 0.15); }

.table-striped > tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.025);
  background-clip: padding-box; }

.table-striped > thead > th {
  background-color: rgba(0, 0, 0, 0.025);
  border-right: 1px solid rgba(160, 175, 185, 0.15); }
  .table-striped > thead > th:last-child {
    border-right: none; }

.table-hover tr:hover td {
  background-color: rgba(160, 175, 185, 0.05); }

.table.v-middle th,
.table.v-middle td {
  vertical-align: middle; }

.table-theme {
  border-spacing: 0; }
  .table-theme thead {
    font-size: 0.7rem; }
  .table-theme tbody td {
    background-color: #fff;
    border-width: 0;
    box-shadow: 0 1px 0 rgba(160, 175, 185, 0.05), 0 -1px 0 rgba(160, 175, 185, 0.05); }
    .table-theme tbody td:first-child {
      border-radius: .25rem 0 0 .25rem; }
    .table-theme tbody td:last-child {
      border-radius: 0 .25rem .25rem 0; }

.table-row {
  border-collapse: separate !important;
  border-spacing: 0 10px !important; }
  .table-row thead th {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .table-row td {
    border-color: transparent !important; }

.sorting_asc,
.sorting_desc,
.sorting,
.sortable {
  position: relative;
  cursor: pointer;
  padding-right: 1rem !important; }
  .sorting_asc:before, .sorting_asc:after,
  .sorting_desc:before,
  .sorting_desc:after,
  .sorting:before,
  .sorting:after,
  .sortable:before,
  .sortable:after {
    opacity: 0.5;
    content: '' !important;
    position: absolute;
    right: 5px !important;
    top: 50%;
    margin-top: 1px;
    width: 0;
    height: 0;
    border-top: 3px solid;
    border-right: 3px solid transparent;
    border-bottom: 0;
    border-left: 3px solid transparent; }
  .sorting_asc:after,
  .sorting_desc:after,
  .sorting:after,
  .sortable:after {
    border-top: 0;
    border-bottom: 3px solid;
    margin-top: -4px; }
  .sorting_asc.desc:before,
  .sorting_desc.desc:before,
  .sorting.desc:before,
  .sortable.desc:before {
    opacity: 1; }
  .sorting_asc.asc:after,
  .sorting_desc.asc:after,
  .sorting.asc:after,
  .sortable.asc:after {
    opacity: 1; }

.sorting_asc:after,
.asc .sorting:after {
  opacity: 1; }

.sorting_desc:after,
.desc .sorting:before {
  opacity: 1; }

/*---------------------------
2. UI Components
---------------------------*/
/*
animate
*/
.animate {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

.animate-reverse {
  animation-direction: reverse; }

.animate-fast {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s; }

.animate-no-delay {
  -webkit-animation-delay: 0s !important;
          animation-delay: 0s !important; }

.animate-delay-1 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animate-delay-2 {
  -webkit-animation-delay: 1s;
          animation-delay: 1s; }

.animate-delay-3 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

.animate-delay-4 {
  -webkit-animation-delay: 2s;
          animation-delay: 2s; }

.sr .card,
.sr .list-item,
.sr .sr-item,
.sr .item {
  visibility: hidden; }

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.slideInLeft {
  -webkit-animation-name: slideInLeft;
          animation-name: slideInLeft; }

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(75%, 0, 0);
            transform: translate3d(75%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(75%, 0, 0);
            transform: translate3d(75%, 0, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.slideInRight {
  -webkit-animation-name: slideInRight;
          animation-name: slideInRight; }

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 75%, 0);
            transform: translate3d(0, 75%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 75%, 0);
            transform: translate3d(0, 75%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp; }

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0); }
  to {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.slideInDown {
  -webkit-animation-name: slideInDown;
          animation-name: slideInDown; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn; }

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-75%, 0, 0);
            transform: translate3d(-75%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
          animation-name: fadeInLeft; }

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(75%, 0, 0);
            transform: translate3d(75%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(75%, 0, 0);
            transform: translate3d(75%, 0, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInRight {
  -webkit-animation-name: fadeInRight;
          animation-name: fadeInRight; }

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 75%, 0);
            transform: translate3d(0, 75%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 75%, 0);
            transform: translate3d(0, 75%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -75%, 0);
            transform: translate3d(0, -75%, 0); }
  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown; }

/*
Aside 
*/
.aside {
  opacity: 1 !important;
  display: flex !important;
  position: relative;
  z-index: 1000; }
  .aside.show {
    width: 100% !important;
    top: 0;
    bottom: 0; }
    .aside.show > * {
      left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); }
  .aside > * {
    -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), -webkit-transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .aside > .modal-dialog {
    margin: 0;
    max-width: inherit;
    pointer-events: inherit; }
  .aside.aside-right > * {
    right: 0;
    left: auto; }
  .aside.aside-right.show > * {
    -webkit-transform: translate3d(0%, 0, 0) !important;
            transform: translate3d(0%, 0, 0) !important; }

.aside-deck {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  right: 0;
  bottom: 0;
  max-height: 80vh;
  z-index: 1050; }

@media (max-width: 1199.98px) {
  .aside-lg {
    display: none;
    opacity: 0;
    position: fixed !important;
    z-index: 1050 !important;
    left: 0; }
    .aside-lg > * {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); }
    .aside-lg ~ * {
      width: 100%; }
    .aside-lg.aside-right > * {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

@media (max-width: 991.98px) {
  .aside-md {
    display: none;
    opacity: 0;
    position: fixed !important;
    background: transparent !important;
    z-index: 1050 !important; }
    .aside-md > * {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); }
    .aside-md ~ * {
      width: 100%; }
    .aside-md.aside-right > * {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-sm {
    display: none;
    opacity: 0;
    background: transparent !important;
    position: fixed !important;
    z-index: 1050 !important; }
    .aside-sm > * {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      max-width: 280px !important;
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0); }
    .aside-sm ~ * {
      width: 100%; }
    .aside-sm.aside-right > * {
      -webkit-transform: translate3d(100%, 0, 0);
              transform: translate3d(100%, 0, 0); } }

/*
Avatar
*/
.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: bold;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15); }
  .avatar img {
    border-radius: inherit;
    width: 100%; }
  .avatar.w-32 .avatar-status {
    margin: 0px; }
  .avatar.w-48 .avatar-status {
    margin: 2px; }
  .avatar.w-56 .avatar-status {
    margin: 3px; }
  .avatar.w-64 .avatar-status {
    margin: 4px; }
  .avatar.w-96 .avatar-status {
    margin: 9px; }
  .avatar.w-128 .avatar-status {
    margin: 14px; }
  .avatar .avatar-status {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }

.avatar-status {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  border-width: 2px;
  border-style: solid;
  border-radius: 100%;
  border-color: #fff;
  background-color: #fff; }
  .bg-dark .avatar-status {
    border-color: #252a3a !important; }
  .avatar-status.no-border {
    width: 8px;
    height: 8px;
    margin: 2px;
    border-color: rgba(0, 0, 0, 0.1); }
  .avatar-status.avatar-center {
    top: 50%;
    margin: 0;
    margin-top: -4px; }
    .avatar-status.avatar-center + img {
      margin: 0 14px 0 24px;
      width: auto; }
  .avatar-status.avatar-top {
    left: 0;
    top: 0; }
  .avatar-status.avatar-right {
    left: auto;
    top: 0;
    right: 0; }
  .avatar-status.avatar-bottom {
    left: auto;
    top: auto;
    bottom: 0;
    right: 0; }
  .avatar-status.avatar-left {
    left: auto;
    top: auto;
    bottom: 0; }
  .avatar-status.on {
    background-color: #31c971; }
  .avatar-status.off {
    background-color: #f5f5f6; }
  .avatar-status.away {
    background-color: #f4c414; }
  .avatar-status.busy {
    background-color: #f54394; }

.avatar-group {
  display: -ms-flex;
  display: flex;
  -ms-align-items: center;
  align-items: center; }
  .avatar-group .avatar {
    position: relative; }
    .avatar-group .avatar + .avatar {
      margin-left: -0.5rem; }
    .avatar-group .avatar:hover, .avatar-group .avatar:active, .avatar-group .avatar.active {
      z-index: 1; }
  .avatar-group.avatar-column .avatar {
    margin: 0.25rem; }

/*
chat
*/
.chat-item {
  display: flex;
  margin-bottom: 1.5rem; }
  .chat-item > * {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }

[data-class="alt"].chat-item,
.chat-item.alt {
  flex-direction: row-reverse; }
  [data-class="alt"].chat-item .chat-body,
  .chat-item.alt .chat-body {
    align-items: flex-end; }
  [data-class="alt"].chat-item .chat-date,
  .chat-item.alt .chat-date {
    text-align: right; }

.chat-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start; }

.chat-content {
  display: inline-flex;
  padding: .75rem 1rem;
  margin-bottom: .25rem; }

.chat-date {
  opacity: 0.5;
  font-size: 0.8em;
  display: block; }

/*
Checkbox and Radio
*/
.ui-check {
  position: relative;
  cursor: pointer; }
  .ui-check input {
    opacity: 0;
    position: absolute;
    z-index: -1; }
    .ui-check input:checked + i {
      background-color: #448bff;
      box-shadow: none; }
      .ui-check input:checked + i:before {
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%) rotate(-45deg);
                transform: translate(-50%, -50%) rotate(-45deg);
        width: 6px;
        height: 6px;
        background-color: #fff; }
    .ui-check input:checked + span .active {
      display: inherit; }
    .ui-check input[type="radio"] + i, .ui-check input[type="radio"] + i:before {
      border-radius: 50%; }
    .ui-check input[type="checkbox"]:checked + i:before {
      -webkit-transform: translate(-50%, -65%) rotate(-45deg);
              transform: translate(-50%, -65%) rotate(-45deg);
      background-color: transparent;
      width: 8px;
      height: 5px;
      border-width: 0 0 2px 2px;
      border-color: #fff;
      border-style: solid; }
    .ui-check input[disabled] + i,
    fieldset[disabled] .ui-check input + i {
      border-color: rgba(135, 150, 165, 0.15);
      opacity: 0.35; }
      .ui-check input[disabled] + i:before,
      fieldset[disabled] .ui-check input + i:before {
        background-color: rgba(135, 150, 165, 0.15); }
  .ui-check > i {
    width: 14px;
    height: 14px;
    line-height: 1;
    box-shadow: 0 0 0 1px rgba(135, 150, 165, 0.35);
    border-radius: 4px;
    display: inline-block;
    vertical-align: middle;
    background-clip: padding-box;
    position: relative;
    left: 0;
    margin-right: 0.3rem; }
    .ui-check > i:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background-color: transparent;
      border-radius: 1px; }
  .ui-check.is-invalid > i {
    box-shadow: inset 0 0 0 2px #f54394; }
  .ui-check.is-valid > i {
    background-color: #31c971; }
  .ui-check > span {
    margin-left: -20px; }
    .ui-check > span .active {
      display: none; }
  .ui-check.ui-check-color i {
    box-shadow: none; }
  .ui-check.ui-check-color input:checked + i:before {
    background-color: #fff; }
  .ui-check.ui-check-rounded > i {
    border-radius: 50%; }

.ui-check-md input:checked + i:before {
  width: 8px;
  height: 8px; }

.ui-check-md input[type="checkbox"]:checked + i:before {
  width: 12px;
  height: 6px; }

.ui-check-md > i {
  width: 20px;
  height: 20px; }

.ui-check-lg input:checked + i:before {
  width: 10px;
  height: 10px; }

.ui-check-lg input[type="checkbox"]:checked + i:before {
  width: 14px;
  height: 8px;
  border-width: 0 0 3px 3px; }

.ui-check-lg > i {
  width: 24px;
  height: 24px; }

/*
Colors
*/
.bg-primary {
  color: #ffffff !important; }

.bg-primary-lt {
  color: #116bff !important;
  background-color: #e1ecff !important; }
  .bg-primary-lt:hover, .bg-primary-lt:focus, .bg-primary-lt.active {
    color: #fff !important;
    background-color: #448bff !important; }

.bg-primary--lt {
  color: #5e676f !important;
  background-color: #e1ecff !important; }

.gd-primary {
  color: #fff;
  border: none;
  background: #448bff linear-gradient(45deg, #448bff, #44e9ff); }

.active > .active-primary {
  color: #448bff !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-primary {
  color: #ffffff !important;
  background-color: #448bff !important; }

.nav-active-text-primary .nav-link.active,
.nav-active-text-primary .nav-item.active > a,
.nav-active-text-primary .nav > li.active > a,
.nav-active-text-primary .nav-sub > li.active > a {
  color: #448bff !important; }

.nav-active-primary .nav-link.active,
.nav-active-primary .nav-item.active > a,
.nav-active-primary .nav > li.active > a {
  color: #ffffff !important;
  background-color: #448bff !important; }

.bg-secondary {
  color: #ffffff !important; }

.bg-secondary-lt {
  color: #3e455a !important;
  background-color: #e3e5e9 !important; }
  .bg-secondary-lt:hover, .bg-secondary-lt:focus, .bg-secondary-lt.active {
    color: #fff !important;
    background-color: #535c78 !important; }

.bg-secondary--lt {
  color: #5e676f !important;
  background-color: #e3e5e9 !important; }

.gd-secondary {
  color: #fff;
  border: none;
  background: #535c78 linear-gradient(45deg, #535c78, #536f78); }

.active > .active-secondary {
  color: #535c78 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-secondary {
  color: #ffffff !important;
  background-color: #535c78 !important; }

.nav-active-text-secondary .nav-link.active,
.nav-active-text-secondary .nav-item.active > a,
.nav-active-text-secondary .nav > li.active > a,
.nav-active-text-secondary .nav-sub > li.active > a {
  color: #535c78 !important; }

.nav-active-secondary .nav-link.active,
.nav-active-secondary .nav-item.active > a,
.nav-active-secondary .nav > li.active > a {
  color: #ffffff !important;
  background-color: #535c78 !important; }

.bg-success {
  color: #ffffff !important; }

.bg-success-lt {
  color: #27a05a !important;
  background-color: #def6e8 !important; }
  .bg-success-lt:hover, .bg-success-lt:focus, .bg-success-lt.active {
    color: #fff !important;
    background-color: #31c971 !important; }

.bg-success--lt {
  color: #5e676f !important;
  background-color: #def6e8 !important; }

.gd-success {
  color: #fff;
  border: none;
  background: #31c971 linear-gradient(45deg, #31c971, #3dc931); }

.active > .active-success {
  color: #31c971 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-success {
  color: #ffffff !important;
  background-color: #31c971 !important; }

.nav-active-text-success .nav-link.active,
.nav-active-text-success .nav-item.active > a,
.nav-active-text-success .nav > li.active > a,
.nav-active-text-success .nav-sub > li.active > a {
  color: #31c971 !important; }

.nav-active-success .nav-link.active,
.nav-active-success .nav-item.active > a,
.nav-active-success .nav > li.active > a {
  color: #ffffff !important;
  background-color: #31c971 !important; }

.bg-info {
  color: #ffffff !important; }

.bg-info-lt {
  color: #1094b5 !important;
  background-color: #d9f4fb !important; }
  .bg-info-lt:hover, .bg-info-lt:focus, .bg-info-lt.active {
    color: #fff !important;
    background-color: #14bae4 !important; }

.bg-info--lt {
  color: #5e676f !important;
  background-color: #d9f4fb !important; }

.gd-info {
  color: #fff;
  border: none;
  background: #14bae4 linear-gradient(45deg, #14bae4, #14e4a6); }

.active > .active-info {
  color: #14bae4 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-info {
  color: #ffffff !important;
  background-color: #14bae4 !important; }

.nav-active-text-info .nav-link.active,
.nav-active-text-info .nav-item.active > a,
.nav-active-text-info .nav > li.active > a,
.nav-active-text-info .nav-sub > li.active > a {
  color: #14bae4 !important; }

.nav-active-info .nav-link.active,
.nav-active-info .nav-item.active > a,
.nav-active-info .nav > li.active > a {
  color: #ffffff !important;
  background-color: #14bae4 !important; }

.bg-warning {
  color: #212529 !important; }

.bg-warning-lt {
  color: #cba20a !important;
  background-color: #fdf6d9 !important; }
  .bg-warning-lt:hover, .bg-warning-lt:focus, .bg-warning-lt.active {
    color: #fff !important;
    background-color: #f4c414 !important; }

.bg-warning--lt {
  color: #5e676f !important;
  background-color: #fdf6d9 !important; }

.gd-warning {
  color: #fff;
  border: none;
  background: #f4c414 linear-gradient(45deg, #f4c414, #f45414); }

.active > .active-warning {
  color: #f4c414 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-warning {
  color: #212529 !important;
  background-color: #f4c414 !important; }

.nav-active-text-warning .nav-link.active,
.nav-active-text-warning .nav-item.active > a,
.nav-active-text-warning .nav > li.active > a,
.nav-active-text-warning .nav-sub > li.active > a {
  color: #f4c414 !important; }

.nav-active-warning .nav-link.active,
.nav-active-warning .nav-item.active > a,
.nav-active-warning .nav > li.active > a {
  color: #ffffff !important;
  background-color: #f4c414 !important; }

.bg-danger {
  color: #ffffff !important; }

.bg-danger-lt {
  color: #f21378 !important;
  background-color: #fde1ee !important; }
  .bg-danger-lt:hover, .bg-danger-lt:focus, .bg-danger-lt.active {
    color: #fff !important;
    background-color: #f54394 !important; }

.bg-danger--lt {
  color: #5e676f !important;
  background-color: #fde1ee !important; }

.gd-danger {
  color: #fff;
  border: none;
  background: #f54394 linear-gradient(45deg, #f54394, #f543ed); }

.active > .active-danger {
  color: #f54394 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-danger {
  color: #ffffff !important;
  background-color: #f54394 !important; }

.nav-active-text-danger .nav-link.active,
.nav-active-text-danger .nav-item.active > a,
.nav-active-text-danger .nav > li.active > a,
.nav-active-text-danger .nav-sub > li.active > a {
  color: #f54394 !important; }

.nav-active-danger .nav-link.active,
.nav-active-danger .nav-item.active > a,
.nav-active-danger .nav > li.active > a {
  color: #ffffff !important;
  background-color: #f54394 !important; }

.bg-light {
  color: #212529 !important; }

.bg-light-lt {
  color: #dadade !important;
  background-color: #fdfdfe !important; }
  .bg-light-lt:hover, .bg-light-lt:focus, .bg-light-lt.active {
    color: #fff !important;
    background-color: #f5f5f6 !important; }

.bg-light--lt {
  color: #5e676f !important;
  background-color: #fdfdfe !important; }

.gd-light {
  color: #fff;
  border: none;
  background: #f5f5f6 linear-gradient(45deg, #f5f5f6, #f5f6f6); }

.active > .active-light {
  color: #f5f5f6 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-light {
  color: #212529 !important;
  background-color: #f5f5f6 !important; }

.nav-active-text-light .nav-link.active,
.nav-active-text-light .nav-item.active > a,
.nav-active-text-light .nav > li.active > a,
.nav-active-text-light .nav-sub > li.active > a {
  color: #f5f5f6 !important; }

.nav-active-light .nav-link.active,
.nav-active-light .nav-item.active > a,
.nav-active-light .nav > li.active > a {
  color: #5e676f !important;
  background-color: #f5f5f6 !important; }

.bg-dark {
  color: #ffffff !important; }

.bg-dark-lt {
  color: #0e1016 !important;
  background-color: #dcdcdf !important; }
  .bg-dark-lt:hover, .bg-dark-lt:focus, .bg-dark-lt.active {
    color: #fff !important;
    background-color: #222635 !important; }

.bg-dark--lt {
  color: #5e676f !important;
  background-color: #dcdcdf !important; }

.gd-dark {
  color: #fff;
  border: none;
  background: #222635 linear-gradient(45deg, #222635, #223035); }

.active > .active-dark {
  color: #222635 !important;
  opacity: 1 !important;
  fill: currentColor; }

.active > .active-bg-dark {
  color: #ffffff !important;
  background-color: #222635 !important; }

.nav-active-text-dark .nav-link.active,
.nav-active-text-dark .nav-item.active > a,
.nav-active-text-dark .nav > li.active > a,
.nav-active-text-dark .nav-sub > li.active > a {
  color: #222635 !important; }

.nav-active-dark .nav-link.active,
.nav-active-dark .nav-item.active > a,
.nav-active-dark .nav > li.active > a {
  color: #ffffff !important;
  background-color: #222635 !important; }

.bg-light {
  color: inherit !important;
  background-color: #f5f5f6; }

.bg-white {
  background-color: #fff; }

.bg--white {
  color: #5e676f;
  background-color: #fff; }

.bg-warning {
  color: #fff !important; }

.bg-dark {
  color: #b9c0d3 !important; }

.bg-black {
  color: #fff;
  background-color: #13182c; }

.bg-white .fill {
  fill: #448bff; }

.bg-white-overlay {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.35); }

.bg-dark-overlay {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.35); }

.bg-img {
  background-size: cover;
  background-position: 50% 50%; }

.gd-overlay {
  background-color: transparent !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
  background-repeat: repeat-x; }

.bg-clip {
  background-clip: padding-box; }

.bg-body {
  background-color: #f9f9fa; }

.no-bg {
  background: transparent !important; }

/*
Layout
*/
.layout,
.layout-row,
.layout-column {
  display: flex; }

.layout-row {
  flex-direction: row; }
  .layout-row > .flex {
    min-width: 0; }

.layout-column {
  flex-direction: column; }
  .layout-column > .flex {
    min-height: 0; }

.flex {
  flex: 1 1 auto; }

.no-shrink {
  flex-shrink: 0; }

.no-grow {
  flex-grow: 0; }

.page-header {
  z-index: 1035 !important;
  -webkit-transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: background-color 0.2s cubic-bezier(0.25, 0.8, 0.25, 1); }
  .page-header.sticky ~ * .page-sidenav.sticky,
  .page-header ~ * .fixed-content {
    top: 3.75rem;
    height: calc(100vh - 3.75rem); }
  .page-header:not(.sticky).scroll-up {
    position: fixed;
    left: 0;
    right: 0; }

.page-sidenav {
  position: relative;
  z-index: 1030 !important;
  min-width: 13.5rem;
  opacity: 1 !important; }
  .page-sidenav.sticky {
    height: 100vh; }

.is-fullscreen.layout-column,
.is-fullscreen.layout-row #content {
  display: block;
  overflow: auto;
  -webkit-overflow-scrolling: touch; }

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  right: 0; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020; }
  .sticky.sticky-bottom {
    top: auto;
    bottom: -1px; }

@media (min-width: 992px) {
  .page-container {
    max-width: 1500px;
    margin: 0 auto; }
  .page-sidenav {
    display: block !important; } }

@media (max-width: 991.98px) {
  body.layout-row {
    flex-direction: column; }
  .page-sidenav {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    position: fixed;
    z-index: 1050 !important;
    display: none;
    opactiy: 0; }
    .bg-dark .page-sidenav, .page-sidenav {
      background: transparent !important; }
    .page-sidenav > * {
      position: fixed;
      height: 100%;
      -webkit-transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
      transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1);
      -webkit-transform: translate3d(-100%, 0, 0);
              transform: translate3d(-100%, 0, 0);
      width: 17.5rem; }
    .page-sidenav.show > div {
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0); } }

@media print {
  .page-sidenav,
  .page-header {
    display: none !important; } }

/*
List
*/
.list {
  padding-left: 0;
  padding-right: 0; }

.list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word; }
  .list-item.block .media {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
  .list-item.block .list-content {
    padding: 1rem; }

.list-content {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  justify-content: center; }

.list-body {
  flex: 1 1 auto; }

.list-footer {
  margin-top: 0.5rem; }

.list-title {
  line-height: 1.42857;
  font-weight: 500;
  display: block;
  color: inherit; }

.list-overlay .media,
.list-hover-overlay .media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: inherit; }
  .list-overlay .media:after,
  .list-hover-overlay .media:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.25); }

.list-overlay .list-content,
.list-hover-overlay .list-content {
  position: relative;
  padding: 1rem;
  z-index: 1; }

.list-overlay .list-body,
.list-hover-overlay .list-body {
  display: flex;
  flex-direction: column; }
  .list-overlay .list-body .list-title,
  .list-hover-overlay .list-body .list-title {
    margin-top: auto; }

.list-hover-overlay:hover .media, .list-hover-overlay:active .media, .list-hover-overlay.active .media {
  display: block; }

.list-hover-overlay .media {
  display: none; }

.list-overlay,
.list-overlay-content,
.list-hover-overlay:hover,
.list-hover-overlay:active,
.list-hover-overlay.active {
  color: #fff !important; }
  .list-overlay a:hover,
  .list-overlay a:active,
  .list-overlay-content a:hover,
  .list-overlay-content a:active,
  .list-hover-overlay:hover a:hover,
  .list-hover-overlay:hover a:active,
  .list-hover-overlay:active a:hover,
  .list-hover-overlay:active a:active,
  .list-hover-overlay.active a:hover,
  .list-hover-overlay.active a:active {
    color: #fff; }
  .list-overlay .text-muted:not(i),
  .list-overlay-content .text-muted:not(i),
  .list-hover-overlay:hover .text-muted:not(i),
  .list-hover-overlay:active .text-muted:not(i),
  .list-hover-overlay.active .text-muted:not(i) {
    color: rgba(255, 255, 255, 0.5) !important; }

.list-hover .media {
  color: #ffffff; }

.list-hover .list-body {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.list-hover:hover, .list-hover.active {
  background-color: #14bae4 !important; }
  .list-hover:hover a:not(.dropdown-item),
  .list-hover:hover a:not(.dropdown-item):hover,
  .list-hover:hover a:not(.dropdown-item):active, .list-hover.active a:not(.dropdown-item),
  .list-hover.active a:not(.dropdown-item):hover,
  .list-hover.active a:not(.dropdown-item):active {
    color: #ffffff; }
  .list-hover:hover .text-muted:not(i), .list-hover.active .text-muted:not(i) {
    color: rgba(255, 255, 255, 0.5) !important; }
  .list-hover:hover .media:after, .list-hover.active .media:after {
    position: relative;
    z-index: 1;
    border-radius: inherit;
    background-color: #14bae4;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(20, 186, 228, 0.5)), to(#14bae4));
    background: linear-gradient(180deg, rgba(20, 186, 228, 0.5), #14bae4); }

.list-hover .media-action-overlay {
  background-color: transparent !important; }

.list-overlay-content {
  color: #fff; }
  .list-overlay-content a:hover {
    color: #fff; }
  .list-overlay-content .media:after {
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: inherit;
    position: relative;
    z-index: 1; }
  .list-overlay-content .list-content {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 1rem; }

.show-row {
  display: none !important; }

.list-row.list-row-12 > div:not(.list-item) {
  flex: 0 0 100%;
  max-width: 100%; }

.list-row .list-item {
  flex-direction: row;
  align-items: center;
  padding: 0.75rem 0.625rem; }
  .list-row .list-item > * {
    padding-left: 0.625rem;
    padding-right: 0.625rem; }
  .list-row .list-item .media {
    min-width: 4rem;
    border-radius: inherit;
    padding: 0; }
  .list-row .list-item .list-content {
    padding: 0 1.25rem;
    text-align: inherit !important; }

.list-row .show-row {
  display: block !important; }

.list-row .hide-row {
  display: none !important; }

.list-bordered .list-item {
  border-top: 1px solid rgba(160, 175, 185, 0.05); }
  .list-bordered .list-item:first-child {
    border-top: none; }

.list-index {
  counter-reset: li; }
  .list-index .list-item:before {
    content: counter(li);
    counter-increment: li;
    width: 3rem;
    padding: 0;
    font-weight: 600;
    flex-shrink: 0; }

@media (min-width: 576px) {
  .list-grouped {
    display: flex;
    flex-direction: row;
    flex-flow: row wrap; }
    .list-grouped > div {
      display: flex; }
      .list-grouped > div > .list-item {
        align-self: stretch;
        flex: 1 1 auto; }
        .list-grouped > div > .list-item .list-content {
          padding-left: 1rem;
          padding-right: 1rem; }
    .list-grouped.no-gutters > div:not(:first-child):not(:last-child):not(:only-child) {
      border-radius: 0; }
    .list-grouped.no-gutters > div:first-child {
      border-radius: 0.25rem 0 0 0.25rem; }
      .list-grouped.no-gutters > div:first-child .media {
        border-radius: inherit; }
    .list-grouped.no-gutters > div:last-child {
      border-radius: 0 0.25rem 0.25rem 0; }
      .list-grouped.no-gutters > div:last-child .media {
        border-radius: inherit; }
    .list-grouped.no-gutters > div .list-item {
      border-radius: inherit; } }

/*
loading
*/
.loading {
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  position: relative; }
  .loading:before, .loading:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 3px solid currentColor;
    border-radius: 50%; }
  .loading:before {
    opacity: 0.3; }
  .loading:after {
    opacity: 0.75;
    border-color: transparent;
    border-left-color: currentColor;
    -webkit-animation: loading 1s linear infinite;
            animation: loading 1s linear infinite; }

.js-Pjax-remove {
  position: absolute; }

.js-Pjax-onswitch {
  position: relative;
  overflow: hidden; }

.page-loading {
  pointer-events: none; }
  .page-loading a, .page-loading {
    pointer-events: none;
    cursor: wait; }
  .page-loading .loading-spin {
    -webkit-animation: loading 1s linear infinite;
            animation: loading 1s linear infinite; }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

/*
Media
*/
.media {
  position: relative;
  display: block;
  padding: 0;
  flex-shrink: 0;
  border-radius: inherit; }
  .media:after {
    content: '';
    display: block;
    padding-top: 100%; }
  .media:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0; }
  .media-circle .media {
    border-radius: 500px; }
    .media-circle .media .media-content:before {
      width: 50%;
      left: 25%; }

.media-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 1rem 1rem;
  z-index: 2;
  display: flex;
  align-items: center;
  color: #fff; }
  .media-overlay.overlay-top {
    bottom: auto; }
  .media-overlay.overlay-bottom {
    top: auto; }

.media-action {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  display: flex;
  align-items: center; }
  .media-action.active {
    opacity: 1; }
  .media-action.media-action-overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    color: #fff;
    padding: 0 5%;
    display: flex;
    justify-content: space-around;
    border-radius: inherit; }
    .media-action.media-action-overlay .btn {
      flex-shrink: 0;
      color: inherit; }

.list-item:hover .media-action,
.list-item:active .media-action,
.media:hover .media-action,
.media:active .media-action,
.active > .media .media-action {
  opacity: 1;
  pointer-events: initial; }

.media iframe,
.media-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  border-radius: inherit;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(120, 120, 120, 0.1);
  display: flex;
  justify-content: center;
  align-items: center; }

.media-content:before {
  content: '';
  position: absolute;
  height: 10%;
  width: 90%;
  left: 5%;
  bottom: 0;
  background: inherit;
  background-position-y: 100%;
  -webkit-filter: blur(10px);
          filter: blur(10px); }
  .circle .media-content:before {
    width: 40%;
    left: 30%; }

.media-21x9:after {
  padding-top: 42.857143%; }

.media-16x9:after {
  padding-top: 56.25%; }

.media-4x3:after {
  padding-top: 75%; }

.media-2x3:after {
  padding-top: 150%; }

.media-3x4:after {
  padding-top: 133.33333%; }

.media-1x2:after {
  padding-top: 200%; }

.media-2x1:after {
  padding-top: 50%; }

.media-3x1:after {
  padding-top: 33%; }

.media-4x1:after {
  padding-top: 25%; }

.media-1-4:after {
  padding-top: 25vh;
  min-height: 10rem; }

.media-1-3:after {
  padding-top: 33vh;
  min-height: 12.5rem; }

.media-1-2:after {
  padding-top: 50vh;
  min-height: 15rem; }

/*
Nav
*/
.sidenav {
  margin: 0;
  pointer-events: inherit;
  display: flex;
  flex-direction: column; }
  .sidenav ul {
    padding-left: 0;
    padding-right: 0;
    list-style: none; }
    .sidenav ul a {
      color: inherit; }
  .sidenav .nav {
    border: inherit;
    display: block; }
    .sidenav .nav li {
      border: inherit;
      position: relative; }
      .sidenav .nav li li a {
        font-size: 0.8rem;
        line-height: 2rem;
        padding-left: 3rem; }
        .sidenav .nav li li a .nav-text {
          padding: 0.4375rem 0; }
      .sidenav .nav li li li a {
        padding-left: 4rem; }
      .sidenav .nav li li li li a {
        padding-left: 5rem; }
      .sidenav .nav li > a {
        display: flex;
        flex-wrap: nowrap;
        padding: 0 0.5rem;
        line-height: 2.5rem;
        position: relative; }
      .sidenav .nav li.active > a {
        background-color: transparent; }
        .sidenav .nav li.active > a .nav-caret:after {
          -webkit-transform: rotate(225deg);
                  transform: rotate(225deg);
          margin-bottom: 0px; }
  .sidenav .nav-header {
    padding: 1rem 1rem 0.5rem 1rem;
    font-size: 85%; }
    .sidenav .nav-header:after {
      display: none; }
  .sidenav .navbar-brand {
    float: none;
    margin-right: 0; }
  .sidenav .flex {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }

.nav-fold {
  display: flex !important;
  align-items: center; }

.nav-text {
  margin: 0 .5rem;
  flex: 1 1 auto;
  line-height: 1.125rem;
  -webkit-transition: opacity .15s ease-in-out;
  transition: opacity .15s ease-in-out;
  padding: 0.6875rem 0; }
  .navbar .nav-text {
    line-height: 1;
    display: inline-block;
    padding: 0; }
    .navbar .nav-text span.text-xs {
      margin-top: 0.25rem; }

.nav-icon {
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  opacity: 0.65; }
  a:hover > .nav-icon,
  a:active > .nav-icon,
  .active > a > .nav-icon {
    opacity: 1; }
  .nav-icon.no-fade {
    opacity: 1; }
  .nav-icon i {
    line-height: 1;
    max-width: 20px;
    max-height: 20px; }
    .nav-icon i svg,
    .nav-icon i img {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%; }
  .navbar .nav-icon {
    margin-right: 0.5rem; }

.nav-badge {
  font-style: normal;
  z-index: 1;
  margin: 0 .5rem; }

.nav-caret {
  width: 1rem;
  opacity: 0.45;
  position: relative; }
  .nav-caret:after {
    content: '';
    width: 5px;
    height: 5px;
    display: inline-block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent currentColor currentColor transparent;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    margin-bottom: 1px; }

.nav-sub {
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease 0s;
  transition: max-height 0.4s ease 0s; }
  @media (prefers-reduced-motion: reduce) {
    .nav-sub {
      -webkit-transition: none;
      transition: none; } }
  .active > .nav-sub,
  .open > .nav-sub {
    max-height: 50rem; }

.nav-border .nav > li.active:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  border-color: inherit;
  border-width: 3px;
  border-left-style: solid; }

.nav-border.right .nav > li.active:after {
  right: 0; }

.nav-stacked .nav > li:not(.nav-header) {
  padding: 0.15rem 0.75rem; }

.nav-stacked .nav > li > a {
  line-height: 2rem;
  padding: 0 0.5rem; }
  .nav-stacked .nav > li > a .nav-icon {
    width: 2rem;
    height: 2rem; }
  .nav-stacked .nav > li > a .nav-text {
    padding: 0.4375rem 0; }
  .nav-stacked .nav > li > a:before {
    left: 0.5rem;
    border-radius: 3px;
    width: 2rem;
    border-width: 1rem; }

.nav-stacked .nav > li li a {
  padding-left: 2.5rem; }

.nav-stacked .nav > li li li a {
  padding-left: 3.5rem; }

.nav-stacked .nav > li li li li a {
  padding-left: 4.5rem; }

.nav-stacked .nav li a {
  border-radius: 3px; }

/*
Nav folded
*/
@media (min-width: 992px) {
  .folded {
    width: 4.5rem;
    min-width: 0; }
  .folded.md {
    width: 5.5rem;
    min-width: 0; }
  .folded .nav-fold {
    justify-content: center !important; }
  .folded .hidden-folded {
    display: none !important; }
  .folded .nav li ul {
    display: none; }
  .folded .nav > li > a .nav-caret,
  .folded .nav > li > a .nav-text {
    display: none; }
  .folded .nav > li > a .nav-badge {
    position: absolute;
    right: 10px; }
  .folded .nav > li > a .nav-icon {
    float: none;
    line-height: 2.5rem;
    font-size: 1rem;
    margin: 0 auto;
    position: relative;
    width: 2.5rem;
    height: 2.5rem; }
  .folded .nav > li > a:before {
    left: 0; }
  .folded .show-text .nav > li > a {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    display: block; }
    .folded .show-text .nav > li > a .nav-text {
      display: block;
      margin-top: -1rem;
      font-size: 0.8em;
      text-align: center; }
  .folded .navbar-brand {
    margin: 0 auto; }
  .folded .nav-stacked .nav > li a {
    padding: 0; }
  .folded .nav-stacked .nav > li:not(.nav-header) {
    padding: 0.25rem 1rem; }
  .folded.nav-expand:hover > *, .folded.nav-expand:focus > *, .folded.nav-expand.active > * {
    width: 13.5rem; }
  .folded.nav-expand:hover .nav-fold, .folded.nav-expand:focus .nav-fold, .folded.nav-expand.active .nav-fold {
    justify-content: start !important; }
  .folded.nav-expand:hover .hidden-folded, .folded.nav-expand:focus .hidden-folded, .folded.nav-expand.active .hidden-folded {
    display: block !important; }
    .folded.nav-expand:hover .hidden-folded.d-inline, .folded.nav-expand:focus .hidden-folded.d-inline, .folded.nav-expand.active .hidden-folded.d-inline {
      display: inline-block !important; }
  .folded.nav-expand:hover .nav li ul, .folded.nav-expand:focus .nav li ul, .folded.nav-expand.active .nav li ul {
    display: inherit; }
  .folded.nav-expand:hover .nav > li > a, .folded.nav-expand:focus .nav > li > a, .folded.nav-expand.active .nav > li > a {
    display: flex; }
    .folded.nav-expand:hover .nav > li > a .nav-caret,
    .folded.nav-expand:hover .nav > li > a .nav-text, .folded.nav-expand:focus .nav > li > a .nav-caret,
    .folded.nav-expand:focus .nav > li > a .nav-text, .folded.nav-expand.active .nav > li > a .nav-caret,
    .folded.nav-expand.active .nav > li > a .nav-text {
      display: block; }
    .folded.nav-expand:hover .nav > li > a .nav-badge, .folded.nav-expand:focus .nav > li > a .nav-badge, .folded.nav-expand.active .nav > li > a .nav-badge {
      position: static; }
    .folded.nav-expand:hover .nav > li > a .nav-icon, .folded.nav-expand:focus .nav > li > a .nav-icon, .folded.nav-expand.active .nav > li > a .nav-icon {
      float: left;
      width: 2.5rem;
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1rem; }
  .folded.nav-expand:hover .show-text .nav > li > a .nav-text, .folded.nav-expand:focus .show-text .nav > li > a .nav-text, .folded.nav-expand.active .show-text .nav > li > a .nav-text {
    text-align: inherit;
    font-size: inherit;
    margin-top: 0; }
  .folded.nav-expand:hover .navbar-brand, .folded.nav-expand:focus .navbar-brand, .folded.nav-expand.active .navbar-brand {
    margin: 0; }
  .folded.nav-expand:hover .nav-stacked .nav > li:not(.nav-header), .folded.nav-expand:focus .nav-stacked .nav > li:not(.nav-header), .folded.nav-expand.active .nav-stacked .nav > li:not(.nav-header) {
    padding: 0.15rem 0.75rem; }
  .folded.nav-expand:hover .nav-stacked .nav > li > a, .folded.nav-expand:focus .nav-stacked .nav > li > a, .folded.nav-expand.active .nav-stacked .nav > li > a {
    padding: 0 0.5rem; }
    .folded.nav-expand:hover .nav-stacked .nav > li > a:before, .folded.nav-expand:focus .nav-stacked .nav > li > a:before, .folded.nav-expand.active .nav-stacked .nav > li > a:before {
      left: 0.5rem;
      width: 2rem;
      border-width: 1rem; }
    .folded.nav-expand:hover .nav-stacked .nav > li > a .nav-icon, .folded.nav-expand:focus .nav-stacked .nav > li > a .nav-icon, .folded.nav-expand.active .nav-stacked .nav > li > a .nav-icon {
      width: 2rem;
      height: 2rem;
      line-height: 2rem; }
  .folded.nav-expand .sidenav {
    -webkit-transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; } }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .folded.nav-expand .sidenav {
      -webkit-transition: none;
      transition: none; } }

@media (min-width: 992px) {
  .nav-dropup .scrollable,
  .nav-dropup > *,
  .folded.nav-dropdown .scrollable,
  .folded.nav-dropdown > * {
    overflow: visible !important; }
  .nav-dropup .nav > li:hover > ul, .nav-dropup .nav > li:focus > ul,
  .folded.nav-dropdown .nav > li:hover > ul,
  .folded.nav-dropdown .nav > li:focus > ul {
    display: block; }
  .nav-dropup .nav > li > ul,
  .folded.nav-dropdown .nav > li > ul {
    display: none;
    overflow: visible;
    max-height: 999px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
    position: absolute;
    left: 100%;
    top: 0;
    padding: 6px 0; }
    .nav-dropup .nav > li > ul a,
    .folded.nav-dropdown .nav > li > ul a {
      padding-left: 16px !important;
      padding-right: 16px !important;
      min-width: 160px; }
    .nav-dropup .nav > li > ul li.active ul,
    .folded.nav-dropdown .nav > li > ul li.active ul {
      display: block; }
    .nav-dropup .nav > li > ul .nav-text,
    .folded.nav-dropdown .nav > li > ul .nav-text {
      padding: 8px 0 !important; }
  .nav-dropup .nav > li .nav-mega,
  .folded.nav-dropdown .nav > li .nav-mega {
    width: 360px; }
    .nav-dropup .nav > li .nav-mega > li,
    .folded.nav-dropdown .nav > li .nav-mega > li {
      width: 50%;
      float: left; } }

/*
Page
*/
.page-bg {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 0;
  height: 50vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 0; }
  .page-bg:before {
    content: '';
    background-size: cover;
    background-repeat: no-repeat;
    background-image: inherit;
    background-position: inherit;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.1; }
  .page-bg:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 50%;
    width: 100%;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0)), to(#f9f9fa));
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #f9f9fa);
    background-repeat: repeat-x; }
  .bg-dark .page-bg:before {
    opacity: 0.15; }
  .bg-dark .page-bg:after {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0)), to(#222635));
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #222635); }

.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: inherit;
  background-position: 50% 50%;
  position: relative; }
  .cover:after {
    position: absolute;
    z-index: 0;
    content: '';
    height: 100%;
    width: 100%;
    top: 0;
    background-color: inherit;
    opacity: 0.65; }
  .cover.cover-gd:after {
    background-image: -webkit-gradient(linear, left top, right top, from(#14bae4), to(#448bff));
    background-image: linear-gradient(to right, #14bae4, #448bff); }
  .cover > * {
    position: relative;
    z-index: 1; }

.swapimg {
  background-size: cover;
  background-repeat: no-repeat;
  width: 50%;
  height: 100%; }
  .swapimg .handler {
    cursor: move;
    position: absolute;
    top: -2.5%;
    right: -1.5rem;
    height: 105%;
    width: 3rem; }
    .swapimg .handler:after {
      content: "";
      position: absolute;
      height: 100%;
      width: 50%;
      border-right: 1px solid rgba(135, 150, 165, 0.1); }

.browser {
  position: relative;
  background-color: #fff;
  border: 1px solid rgba(135, 150, 165, 0.1);
  background-clip: padding-box;
  padding-top: 28px;
  border-radius: 4px;
  overflow: hidden; }
  .browser:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 8px rgba(135, 150, 165, 0.1), 14px 0 rgba(135, 150, 165, 0.1), 28px 0 rgba(135, 150, 165, 0.1);
    display: block;
    position: absolute;
    top: 10px;
    left: 8px; }
  .browser.colored:before {
    box-shadow: inset 0 0 0 8px #fc605c, 14px 0 #fdbc40, 28px 0 #34c84a; }

/*
Plugins
*/
.fc-toolbar {
  margin-bottom: 0 !important;
  padding: 0.75rem 2rem; }
  .fc-toolbar h2 {
    font-size: 0.8rem;
    padding-top: 8px;
    margin-right: 8px;
    color: inherit; }
  .fc-toolbar button {
    background: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    color: inherit;
    text-shadow: none;
    box-shadow: none; }

.fc-head th {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); }

.bg-dark .fc-head th {
  background-color: #222635;
  color: inherit; }

.fc-event {
  border-width: 0;
  padding: 5px 8px;
  color: inherit; }

.fc-event:hover {
  color: inherit; }

.fc-event .fc-bg {
  background-color: transparent; }

.fc td,
.fc tr,
.fc th {
  border-color: rgba(160, 175, 185, 0.05); }

.fc .popover {
  min-width: 220px; }

.fc-content .fc-time {
  display: block;
  margin-bottom: 0.25rem;
  font-weight: normal !important; }

.fc-content .fc-title {
  font-weight: 600; }

.fc-day-number {
  font-size: 0.8em;
  opacity: 0.5; }

td.fc-today {
  background: transparent !important; }

.fc-today .fc-day-number {
  background: #448bff;
  color: white;
  opacity: 1;
  padding: 0px 3px; }

.fc-today.fc-widget-header {
  color: #448bff !important; }

.fc-divider {
  border: none !important;
  background-color: rgba(160, 175, 185, 0.05) !important; }

.fc-axis span {
  opacity: 0.6;
  font-size: 11px; }

.fc-head-container.fc-widget-header,
.fc-body > tr > .fc-widget-content {
  border-left-width: 0 !important;
  border-right-width: 0 !important; }

.fc-widget-header th {
  text-align: left !important;
  padding: 12px; }

.fc-widget-header .fc-week-title {
  display: block;
  font-weight: 400;
  font-size: 11px; }

.fc-other-month {
  background-image: linear-gradient(45deg, rgba(120, 130, 140, 0.02) 25%, transparent 25%, transparent 50%, rgba(120, 130, 140, 0.02) 50%, rgba(120, 130, 140, 0.02) 75%, transparent 75%, transparent);
  background-size: 1.5rem 1.5rem; }

.fc .fc-axis {
  border: 0; }

.fc-header-toolbar,
.fc-head-container.fc-widget-header {
  background-color: #fff; }
  .bg-dark .fc-header-toolbar, .bg-dark
  .fc-head-container.fc-widget-header {
    background-color: transparent; }

.fc-scroller {
  overflow: visible !important; }

/*JQVmap*/
div.jqvmap-zoomin,
div.jqvmap-zoomout {
  width: 18px;
  height: 18px;
  background-color: #fff;
  color: #666;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); }

/*Charts*/
.ct-label {
  color: rgba(130, 140, 155, 0.65);
  fill: rgba(130, 140, 155, 0.65); }

.ct-grid {
  stroke: rgba(160, 175, 185, 0.15); }

.chartist-tooltip {
  position: absolute;
  display: inline-block;
  opacity: 0;
  min-width: 2rem;
  padding: .25rem .5rem;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 3px;
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  pointer-events: none;
  z-index: 1;
  -webkit-transition: opacity .2s linear;
  transition: opacity .2s linear; }

.chartist-tooltip-meta {
  color: rgba(255, 255, 255, 0.8); }

.chartist-tooltip:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -4px;
  border: 4px solid transparent;
  border-top-color: rgba(0, 0, 0, 0.8); }

.chartist-tooltip.tooltip-show {
  opacity: 1; }

.ct-area, .ct-line {
  pointer-events: none; }

.ct-stroke-1 .ct-line {
  stroke-width: 1px; }

.ct-stroke-2 .ct-line {
  stroke-width: 2px; }

.ct-stroke-3 .ct-line {
  stroke-width: 3px; }

.ct-stroke-4 .ct-line {
  stroke-width: 4px; }

.ct-stroke-5 .ct-line {
  stroke-width: 5px; }

.ct-point-hover .ct-point {
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
  opacity: 0 !important; }
  .ct-point-hover .ct-point:hover, .ct-point-hover .ct-point:focus {
    opacity: 1 !important; }

.ct-series-a .ct-point, .ct-series-a .ct-line, .ct-series-a .ct-bar, .ct-series-a .ct-slice-donut {
  stroke: #2196f3; }

.ct-series-a .ct-slice-pie, .ct-series-a .ct-slice-donut-solid, .ct-series-a .ct-area {
  fill: #2196f3; }

.ct-series-b .ct-point, .ct-series-b .ct-line, .ct-series-b .ct-bar, .ct-series-b .ct-slice-donut {
  stroke: #03a9f4; }

.ct-series-b .ct-slice-pie, .ct-series-b .ct-slice-donut-solid, .ct-series-b .ct-area {
  fill: #03a9f4; }

.ct-series-c .ct-point, .ct-series-c .ct-line, .ct-series-c .ct-bar, .ct-series-c .ct-slice-donut {
  stroke: #00bcd4; }

.ct-series-c .ct-slice-pie, .ct-series-c .ct-slice-donut-solid, .ct-series-c .ct-area {
  fill: #00bcd4; }

.ct-series-d .ct-point, .ct-series-d .ct-line, .ct-series-d .ct-bar, .ct-series-d .ct-slice-donut {
  stroke: #009688; }

.ct-series-d .ct-slice-pie, .ct-series-d .ct-slice-donut-solid, .ct-series-d .ct-area {
  fill: #009688; }

.ct-series-e .ct-point, .ct-series-e .ct-line, .ct-series-e .ct-bar, .ct-series-e .ct-slice-donut {
  stroke: #4caf50; }

.ct-series-e .ct-slice-pie, .ct-series-e .ct-slice-donut-solid, .ct-series-e .ct-area {
  fill: #4caf50; }

.ct-series-f .ct-point, .ct-series-f .ct-line, .ct-series-f .ct-bar, .ct-series-f .ct-slice-donut {
  stroke: #8bc34a; }

.ct-series-f .ct-slice-pie, .ct-series-f .ct-slice-donut-solid, .ct-series-f .ct-area {
  fill: #8bc34a; }

.ct-series-g .ct-point, .ct-series-g .ct-line, .ct-series-g .ct-bar, .ct-series-g .ct-slice-donut {
  stroke: #cddc39; }

.ct-series-g .ct-slice-pie, .ct-series-g .ct-slice-donut-solid, .ct-series-g .ct-area {
  fill: #cddc39; }

.ct-series-h .ct-point, .ct-series-h .ct-line, .ct-series-h .ct-bar, .ct-series-h .ct-slice-donut {
  stroke: #ffeb3b; }

.ct-series-h .ct-slice-pie, .ct-series-h .ct-slice-donut-solid, .ct-series-h .ct-area {
  fill: #ffeb3b; }

.ct-series-i .ct-point, .ct-series-i .ct-line, .ct-series-i .ct-bar, .ct-series-i .ct-slice-donut {
  stroke: #ffc107; }

.ct-series-i .ct-slice-pie, .ct-series-i .ct-slice-donut-solid, .ct-series-i .ct-area {
  fill: #ffc107; }

.ct-series-j .ct-point, .ct-series-j .ct-line, .ct-series-j .ct-bar, .ct-series-j .ct-slice-donut {
  stroke: #ff9800; }

.ct-series-j .ct-slice-pie, .ct-series-j .ct-slice-donut-solid, .ct-series-j .ct-area {
  fill: #ff9800; }

.ct-series-k .ct-point, .ct-series-k .ct-line, .ct-series-k .ct-bar, .ct-series-k .ct-slice-donut {
  stroke: #ff5722; }

.ct-series-k .ct-slice-pie, .ct-series-k .ct-slice-donut-solid, .ct-series-k .ct-area {
  fill: #ff5722; }

.ct-series-l .ct-point, .ct-series-l .ct-line, .ct-series-l .ct-bar, .ct-series-l .ct-slice-donut {
  stroke: #795548; }

.ct-series-l .ct-slice-pie, .ct-series-l .ct-slice-donut-solid, .ct-series-l .ct-area {
  fill: #795548; }

.ct-series-m .ct-point, .ct-series-m .ct-line, .ct-series-m .ct-bar, .ct-series-m .ct-slice-donut {
  stroke: #607d8b; }

.ct-series-m .ct-slice-pie, .ct-series-m .ct-slice-donut-solid, .ct-series-m .ct-area {
  fill: #607d8b; }

.ct-series-n .ct-point, .ct-series-n .ct-line, .ct-series-n .ct-bar, .ct-series-n .ct-slice-donut {
  stroke: #9e9e9e; }

.ct-series-n .ct-slice-pie, .ct-series-n .ct-slice-donut-solid, .ct-series-n .ct-area {
  fill: #9e9e9e; }

.ct-series-o .ct-point, .ct-series-o .ct-line, .ct-series-o .ct-bar, .ct-series-o .ct-slice-donut {
  stroke: #ababab; }

.ct-series-o .ct-slice-pie, .ct-series-o .ct-slice-donut-solid, .ct-series-o .ct-area {
  fill: #ababab; }

.ct-stroke-1 .ct-line {
  stroke-width: 1px; }

.ct-stroke-1 .ct-bar,
.ct-stroke-1 .ct-point {
  stroke-width: 2px; }

.ct-stroke-2 .ct-line {
  stroke-width: 2px; }

.ct-stroke-2 .ct-bar,
.ct-stroke-2 .ct-point {
  stroke-width: 4px; }

.ct-stroke-3 .ct-line {
  stroke-width: 3px; }

.ct-stroke-3 .ct-bar,
.ct-stroke-3 .ct-point {
  stroke-width: 6px; }

.ct-stroke-4 .ct-line {
  stroke-width: 4px; }

.ct-stroke-4 .ct-bar,
.ct-stroke-4 .ct-point {
  stroke-width: 8px; }

.bg-dark .btn-default.form-control {
  background: transparent !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  color: inherit !important; }

.ui-select-bootstrap > .ui-select-choices {
  overflow-y: scroll; }

.dataTables_wrapper {
  padding: 0; }

.bootstrap-table .fixed-table-loading {
  display: none;
  position: absolute;
  top: 42px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
  text-align: center; }

.bootstrap-table .fixed-table-toolbar i {
  display: inline-block;
  min-height: 14px; }

.note-editor {
  border-width: 0 !important;
  overflow: visible !important;
  background-color: inherit;
  margin: 0; }

.note-editor .note-editing-area .note-editable,
.note-editor .note-statusbar {
  background-color: transparent !important;
  color: inherit !important; }

.note-toolbar {
  border-width: 0; }

.note-editor .btn-light {
  background: transparent !important;
  border: none !important;
  color: inherit !important;
  opacity: 0.5; }

.note-editor .btn-light.active {
  opacity: 1; }

.note-editor-inline .note-editor {
  box-shadow: none; }

.note-editor-inline .note-editing-area {
  border: 1px solid rgba(135, 150, 165, 0.15);
  background: #fff; }

.bg-dark .note-editor-inline .note-editing-area {
  background: transparent; }

.select2-container--default .select2-selection {
  min-height: 33px;
  border-color: rgba(135, 150, 165, 0.15) !important; }
  .bg-dark .select2-container--default .select2-selection {
    background-color: #242838; }
  .select2-container--default .select2-selection .select2-selection__rendered {
    color: inherit;
    padding-left: 9px; }
  .select2-container--default .select2-selection .select2-selection__arrow {
    top: 3px; }
  .select2-container--default .select2-selection .select2-selection__choice {
    background: transparent;
    border-color: rgba(135, 150, 165, 0.15);
    line-height: 1.5; }
  .select2-container--default .select2-selection .select2-selection__choice__remove {
    color: inherit !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 30px; }

.select2-container--default .select2-search--inline .select2-search__field {
  margin-top: 6px; }

.select2-container--default .select2-search__field {
  color: inherit; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: rgba(135, 150, 165, 0.15); }

.select2-container--default .select2-dropdown {
  color: #5e676f;
  border-color: rgba(135, 150, 165, 0.15); }

.datepicker {
  width: auto;
  padding: 0;
  font-size: 0.7rem; }
  .datepicker.dropdown-menu {
    padding: 4px; }
  .datepicker table {
    width: 100%; }
    .datepicker table tr td span.active, .datepicker table tr td span.active:hover, .datepicker table tr td span.active:active,
    .datepicker table tr td.active,
    .datepicker table tr td.active:hover,
    .datepicker table tr td.active:active,
    .datepicker table tr td.selected,
    .datepicker table tr td.selected:hover,
    .datepicker table tr td.selected:active,
    .datepicker table tr td.today,
    .datepicker table tr td.today:hover,
    .datepicker table tr td.today:active {
      background-image: none;
      color: white;
      background-color: #448bff !important; }
    .datepicker table td,
    .datepicker table th {
      height: auto;
      line-height: 1.5 !important;
      border-width: 0;
      vertical-align: middle;
      padding: 4px !important; }
      .datepicker table td span:not(.active), .datepicker table td:not(.active):hover,
      .datepicker table th span:not(.active),
      .datepicker table th:not(.active):hover {
        background-color: transparent; }
      .datepicker table td span,
      .datepicker table th span {
        height: auto !important;
        line-height: 2 !important; }
    .datepicker table .disabled {
      opacity: 0.4; }

.daterangepicker {
  color: #333; }
  .daterangepicker .ranges li.active {
    background-color: #448bff; }

.notie-container {
  display: flex;
  align-items: center;
  position: fixed;
  height: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 1050;
  box-sizing: border-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  padding: 0.875rem 1.5rem;
  background-color: #13182c;
  color: white;
  border-radius: 2px;
  max-width: 35rem;
  min-width: 18rem; }
  .notie-container .btn {
    padding: 0px 6px;
    line-height: 18px;
    cursor: pointer;
    box-shadow: none !important; }
  .notie-container .btn:empty {
    display: none; }

.notie-textbox {
  flex: 1 1;
  margin-right: 1.5rem; }

.notie-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: rgba(255, 255, 255, 0); }

/*
Switch
*/
.ui-switch {
  cursor: pointer;
  position: relative;
  display: inline-block;
  width: 24px;
  height: 14px;
  border-radius: 30px;
  background-color: #448bff;
  margin: 0; }
  .ui-switch input {
    position: absolute;
    opacity: 0;
    z-index: -1; }
    .ui-switch input:checked + i:before {
      top: 50%;
      bottom: 50%;
      left: 50%;
      right: 5px;
      border-width: 0;
      border-radius: 5px; }
    .ui-switch input:checked + i:after {
      margin-left: 11px; }
  .ui-switch i {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%; }
    .ui-switch i:before {
      content: "";
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      background-color: #fff;
      border: 1px solid rgba(135, 150, 165, 0.15);
      border-radius: 30px;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      @media (prefers-reduced-motion: reduce) {
        .ui-switch i:before {
          -webkit-transition: none;
          transition: none; } }
    .ui-switch i:after {
      content: "";
      position: absolute;
      background-color: #fff;
      width: 12px;
      top: 1px;
      bottom: 1px;
      border-radius: 50%;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
      -webkit-transition: margin 0.3s;
      transition: margin 0.3s; }
      @media (prefers-reduced-motion: reduce) {
        .ui-switch i:after {
          -webkit-transition: none;
          transition: none; } }

.ui-switch-md {
  width: 32px;
  height: 20px; }
  .ui-switch-md input:checked + i:after {
    margin-left: 13px; }
  .ui-switch-md i:after {
    width: 18px; }

.ui-switch-lg {
  width: 40px;
  height: 24px; }
  .ui-switch-lg input:checked + i:after {
    margin-left: 17px; }
  .ui-switch-lg i:after {
    width: 22px; }

/*
Timeline
*/
.timeline {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15);
  padding: 0;
  margin: 0; }

.tl-item {
  border-radius: 3px;
  position: relative;
  display: flex; }
  .tl-item > * {
    padding: 10px; }
  .tl-item .avatar {
    z-index: 2; }
  .tl-item:last-child .tl-dot:after {
    display: none; }
  .tl-item.active .tl-dot:before {
    border-color: #448bff;
    box-shadow: 0 0 0px 4px rgba(68, 139, 255, 0.2); }

.tl-dot {
  position: relative;
  border-color: rgba(160, 175, 185, 0.15); }
  .tl-dot:before, .tl-dot:after {
    content: '';
    position: absolute;
    border-color: inherit;
    border-width: 2px;
    border-style: solid;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    top: 15px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
  .tl-dot:after {
    width: 0;
    height: auto;
    top: 25px;
    bottom: -15px;
    border-right-width: 0;
    border-top-width: 0;
    border-bottom-width: 0;
    border-radius: 0; }

.tl-content p:last-child {
  margin-bottom: 0; }

.tl-date {
  font-size: 0.85em;
  margin-top: 2px;
  min-width: 100px;
  max-width: 100px; }

/*---------------------------
3. Utilities
---------------------------*/
/*
Base
*/
.pos-rlt {
  position: relative; }

.pos-abt {
  position: absolute; }

.pos-fix {
  position: fixed !important; }

.pos-stc {
  position: static !important; }

.active > .d-inline,
.active > .auto .d-inline {
  display: none !important; }

.active > .d-none,
.active > .auto .d-none {
  display: inline-block !important; }

.box-shadow {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05); }

.box-shadows {
  box-shadow: 0 10px 25px 0 rgba(50, 50, 50, 0.15); }

.hide {
  display: none !important; }

.hidden {
  visibility: hidden; }

.show-rtl {
  display: none !important; }

.pointer {
  cursor: pointer; }

.circle {
  border-radius: 500px; }

.clear {
  display: block;
  overflow: hidden; }

.no-wrap {
  white-space: nowrap; }

.no-shadow {
  box-shadow: none !important; }

.no-select {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.p-2-3 {
  padding: 0.75rem; }

.p-3-4 {
  padding: 1.25rem; }

.scale {
  -webkit-transform: scale(0.9);
          transform: scale(0.9); }

.scale-75 {
  -webkit-transform: scale(0.75);
          transform: scale(0.75); }

.scale-50 {
  -webkit-transform: scale(0.5);
          transform: scale(0.5); }

.scale-25 {
  -webkit-transform: scale(0.25);
          transform: scale(0.25); }

@media (max-width: 767.98px) {
  .pos-stc-sm {
    position: static !important; } }

@media (max-width: 575.98px) {
  .pos-stc-xs {
    position: static !important; } }

/*
Border
*/
.no-border {
  border-color: transparent !important;
  border-width: 0 !important; }

.b {
  border-color: rgba(160, 175, 185, 0.15);
  background-clip: padding-box; }

.b-a {
  border: 1px solid rgba(160, 175, 185, 0.15); }

.b-t {
  border-top: 1px solid rgba(160, 175, 185, 0.15); }

.b-r {
  border-right: 1px solid rgba(160, 175, 185, 0.15); }

.b-b {
  border-bottom: 1px solid rgba(160, 175, 185, 0.15); }

.b-l {
  border-left: 1px solid rgba(160, 175, 185, 0.15); }

.b-1x {
  border-width: 1px; }

.b-2x {
  border-width: 2px; }

.b-3x {
  border-width: 3px; }

.b-4x {
  border-width: 4px; }

.b-5x {
  border-width: 5px; }

.b-t-2x {
  border-top-width: 2px !important; }

.b-t-3x {
  border-top-width: 3px !important; }

.b-t-4x {
  border-top-width: 4px !important; }

.b-t-5x {
  border-top-width: 5px !important; }

.b-r-2x {
  border-right-width: 2px !important; }

.b-r-3x {
  border-right-width: 3px !important; }

.b-r-4x {
  border-right-width: 4px !important; }

.b-r-5x {
  border-right-width: 5px !important; }

.b-b-2x {
  border-bottom-width: 2px !important; }

.b-b-3x {
  border-bottom-width: 3px !important; }

.b-b-4x {
  border-bottom-width: 4px !important; }

.b-b-5x {
  border-bottom-width: 5px !important; }

.b-l-2x {
  border-left-width: 2px !important; }

.b-l-3x {
  border-left-width: 3px !important; }

.b-l-4x {
  border-left-width: 4px !important; }

.b-l-5x {
  border-left-width: 5px !important; }

.b-light {
  border-color: #f5f5f6; }

.b-white {
  border-color: #fff; }

.b-primary {
  border-color: #448bff !important; }

.b-t-primary {
  border-top-color: #448bff; }

.b-r-primary {
  border-right-color: #448bff; }

.b-b-primary {
  border-bottom-color: #448bff; }

.b-l-primary {
  border-left-color: #448bff; }

.b-success {
  border-color: #31c971 !important; }

.b-t-success {
  border-top-color: #31c971; }

.b-r-success {
  border-right-color: #31c971; }

.b-b-success {
  border-bottom-color: #31c971; }

.b-l-success {
  border-left-color: #31c971; }

.b-info {
  border-color: #14bae4 !important; }

.b-t-info {
  border-top-color: #14bae4; }

.b-r-info {
  border-right-color: #14bae4; }

.b-b-info {
  border-bottom-color: #14bae4; }

.b-l-info {
  border-left-color: #14bae4; }

.b-warning {
  border-color: #f4c414 !important; }

.b-t-warning {
  border-top-color: #f4c414; }

.b-r-warning {
  border-right-color: #f4c414; }

.b-b-warning {
  border-bottom-color: #f4c414; }

.b-l-warning {
  border-left-color: #f4c414; }

.b-danger {
  border-color: #f54394 !important; }

.b-t-danger {
  border-top-color: #f54394; }

.b-r-danger {
  border-right-color: #f54394; }

.b-b-danger {
  border-bottom-color: #f54394; }

.b-l-danger {
  border-left-color: #f54394; }

.b-light {
  border-color: #f5f5f6 !important; }

.b-t-light {
  border-top-color: #f5f5f6; }

.b-r-light {
  border-right-color: #f5f5f6; }

.b-b-light {
  border-bottom-color: #f5f5f6; }

.b-l-light {
  border-left-color: #f5f5f6; }

.b-dark {
  border-color: #222635 !important; }

.b-t-dark {
  border-top-color: #222635; }

.b-r-dark {
  border-right-color: #222635; }

.b-b-dark {
  border-bottom-color: #222635; }

.b-l-dark {
  border-left-color: #222635; }

.b-white {
  border-color: #ffffff !important; }

.b-t-white {
  border-top-color: #ffffff; }

.b-r-white {
  border-right-color: #ffffff; }

.b-b-white {
  border-bottom-color: #ffffff; }

.b-l-white {
  border-left-color: #ffffff; }

.b-default {
  border-color: rgba(160, 175, 185, 0.15) !important; }

.b-t-default {
  border-top-color: rgba(160, 175, 185, 0.15); }

.b-r-default {
  border-right-color: rgba(160, 175, 185, 0.15); }

.b-b-default {
  border-bottom-color: rgba(160, 175, 185, 0.15); }

.b-l-default {
  border-left-color: rgba(160, 175, 185, 0.15); }

.no-b-t {
  border-top-width: 0; }

.no-b-r {
  border-right-width: 0; }

.no-b-b {
  border-bottom-width: 0; }

.no-b-l {
  border-left-width: 0; }

.b-dashed {
  border-style: dashed !important; }

.b-transparent {
  border-color: transparent !important; }

@media (max-width: 991.98px) {
  .no-border-md {
    border-width: 0; } }

@media (max-width: 767.98px) {
  .no-border-sm {
    border-width: 0; } }

@media (max-width: 575.98px) {
  .no-border-xs {
    border-width: 0; } }

/*
Hover
*/
.hover-show {
  display: none; }

.hover-rotate {
  -webkit-transition: all 0.2s ease-in-out 0.1s;
  transition: all 0.2s ease-in-out 0.1s; }
  @media (prefers-reduced-motion: reduce) {
    .hover-rotate {
      -webkit-transition: none;
      transition: none; } }

.hover-anchor:hover .hover-show,
.hover-anchor:active .hover-show {
  display: inherit; }

.hover-anchor:hover .hover-rotate,
.hover-anchor:active .hover-rotate {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

.hover-top:hover,
.hover-top:active {
  position: relative;
  z-index: 1000; }

/*
Radius
*/
.r {
  border-radius: 3px; }

.r-t {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px; }

.r-r {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px; }

.r-b {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px; }

.r-l {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px; }

.r-2x {
  border-radius: 6px; }

.r-3x {
  border-radius: 9px; }

.r-auto {
  border-radius: inherit; }

.no-radius {
  border-radius: 0 !important; }

.no-r-t {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.no-r-r {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.no-r-b {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.no-r-l {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

/*
Scrollable
*/
.scrollable {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  .scrollable.hover {
    overflow-y: hidden; }
    .scrollable.hover > * {
      margin-top: -1px; }
    .scrollable.hover:hover, .scrollable.hover:focus, .scrollable.hover:active {
      overflow: visible;
      overflow-y: auto; }
  .touch .scrollable {
    overflow-y: auto !important; }

.scroll-x,
.scroll-y {
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.scroll-y {
  overflow-y: auto; }

.scroll-x {
  overflow-x: auto; }

.no-scroll {
  overflow: hidden; }

/*
Text
*/
a.primary:hover {
  background-color: #3b85ff; }

a.text-primary:hover {
  color: #3b85ff !important; }

.text-primary,
.text-hover-primary a:hover,
.text-hover-primary .active > a {
  color: #448bff !important; }

a.success:hover {
  background-color: #2fc26d; }

a.text-success:hover {
  color: #2fc26d !important; }

.text-success,
.text-hover-success a:hover,
.text-hover-success .active > a {
  color: #31c971 !important; }

a.info:hover {
  background-color: #13b3dc; }

a.text-info:hover {
  color: #13b3dc !important; }

.text-info,
.text-hover-info a:hover,
.text-hover-info .active > a {
  color: #14bae4 !important; }

a.warning:hover {
  background-color: #f4c20b; }

a.text-warning:hover {
  color: #f4c20b !important; }

.text-warning,
.text-hover-warning a:hover,
.text-hover-warning .active > a {
  color: #f4c414 !important; }

a.danger:hover {
  background-color: #f53b8f; }

a.text-danger:hover {
  color: #f53b8f !important; }

.text-danger,
.text-hover-danger a:hover,
.text-hover-danger .active > a {
  color: #f54394 !important; }

a.dark:hover {
  background-color: #1f2230; }

a.text-dark:hover {
  color: #1f2230 !important; }

.text-dark,
.text-hover-dark a:hover,
.text-hover-dark .active > a {
  color: #222635 !important; }

a.blue:hover {
  background-color: #0077f6; }

a.text-blue:hover {
  color: #0077f6 !important; }

.text-blue,
.text-hover-blue a:hover,
.text-hover-blue .active > a {
  color: #007bff !important; }

.text-white {
  color: white !important; }

.text-color {
  color: #5e676f; }

.text-inherit a {
  color: inherit; }

.text-fade {
  opacity: 0.65; }

.text-gd {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  text-fill-color: transparent; }

.text {
  font-size: 1rem; }

.text-sm {
  font-size: 0.825rem; }

.text-md {
  font-size: 1.25rem; }

.text-lg {
  font-size: 2.5rem; }

.text-2x {
  font-size: 2em !important; }

.text-3x {
  font-size: 3em !important; }

.text-4x {
  font-size: 4em !important; }

.l-h-0 {
  line-height: 0; }

.l-h {
  line-height: 1.42857; }

.l-h-1x {
  line-height: 1; }

.l-h-2x {
  line-height: 2em; }

.l-s-1x {
  letter-spacing: 1px; }

.l-s-2x {
  letter-spacing: 2px; }

.l-s-3x {
  letter-spacing: 3px; }

.l-s-4x {
  letter-spacing: 4px; }

.l-s-n-1x {
  letter-spacing: -1px; }

.l-s-n-2x {
  letter-spacing: -2px; }

.l-s-n-3x {
  letter-spacing: -3px; }

.l-s-n-4x {
  letter-spacing: -4px; }

.h-1x {
  height: 1.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }

.h-2x {
  max-height: 2.5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; }

.h-3x {
  max-height: 3.75rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.h-4x {
  max-height: 5rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }

.text-serif {
  font-family: Georgia, "Times New Roman", Times, serif; }

.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.text-shadow {
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.1); }

.font-weight-100 {
  font-weight: 100 !important; }

.font-weight-300 {
  font-weight: 300 !important; }

.font-weight-500 {
  font-weight: 500 !important; }

.text-align-auto {
  text-align: inherit; }

@media (max-width: 991.98px) {
  .display-1 {
    font-size: 3.5rem; }
  .display-2 {
    font-size: 3.25rem; }
  .display-3 {
    font-size: 3rem; }
  .display-4 {
    font-size: 1.5rem; }
  .text-lg {
    font-size: 2rem; } }

/*
Size
*/
.w-8 {
  width: 8px !important;
  height: 8px !important; }

.w-12 {
  width: 12px !important;
  height: 12px !important; }

.w-16 {
  width: 16px !important;
  height: 16px !important; }

.w-20 {
  width: 20px !important;
  height: 20px !important;
  font-size: 0.6em; }

.w-24 {
  width: 24px !important;
  height: 24px !important;
  font-size: 0.7em; }

.w-28 {
  width: 28px !important;
  height: 28px !important;
  font-size: 0.8em; }

.w-32 {
  width: 32px !important;
  height: 32px !important;
  font-size: 0.85em; }

.w-36 {
  width: 36px !important;
  height: 36px !important;
  font-size: 0.875em; }

.w-40 {
  width: 40px !important;
  height: 40px !important; }

.w-48 {
  width: 48px !important;
  height: 48px !important; }

.w-56 {
  width: 56px !important;
  height: 56px !important; }

.w-64 {
  width: 64px !important;
  height: 64px !important; }

.w-72 {
  width: 72px !important;
  height: 72px !important; }

.w-80 {
  width: 80px; }

.w-96 {
  width: 96px; }

.w-128 {
  width: 128px; }

.w-xs {
  width: 90px; }

.w-sm {
  width: 120px; }

.w {
  width: 190px; }

.w-md {
  width: 240px; }

.w-lg {
  width: 280px; }

.w-xl {
  width: 320px; }

.w-auto {
  width: auto; }

.h-auto {
  height: auto; }

.hv {
  height: 100vh; }

.h-v {
  min-height: 100vh; }

.h-v-50 {
  min-height: 50vh; }

@media (max-width: 1199.98px) {
  .w-auto-lg {
    width: auto !important; }
  .w-100-lg {
    width: 100% !important; } }

@media (max-width: 991.98px) {
  .w-auto-md {
    width: auto !important; }
  .w-100-md {
    width: 100% !important; } }

@media (max-width: 767.98px) {
  .w-auto-sm {
    width: auto !important; }
  .w-100-sm {
    width: 100% !important; } }

@media (max-width: 575.98px) {
  .w-auto-xs {
    width: auto !important; }
  .w-100-xs {
    width: 100% !important; } }

/*---------------------------
4. Material Design style
---------------------------*/
.md-form-group {
  padding: 18px 0 24px 0;
  position: relative; }

.md-input {
  background: transparent;
  position: relative;
  z-index: 5;
  border: 0;
  box-shadow: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(135, 150, 165, 0.15);
  width: 100%;
  height: 34px;
  padding: 2px;
  color: inherit; }
  .md-input:focus, .md-input.focus {
    border-color: #448bff;
    border-bottom-width: 2px;
    padding-bottom: 1px; }
    .md-input:focus ~ label, .md-input.focus ~ label {
      color: #448bff;
      opacity: 1;
      top: 0 !important;
      font-size: 0.85em !important; }
  .float-label .md-input ~ label {
    top: 20px;
    font-size: 1em; }
  .float-label .md-input:not([value=""]) ~ label, .float-label .md-input:valid ~ label {
    top: 0;
    font-size: 0.85em; }
  .md-input ~ label {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    font-size: 0.85em;
    position: absolute;
    z-index: 0;
    opacity: 0.5;
    display: inline-block;
    top: 0px;
    left: 0; }
    @media (prefers-reduced-motion: reduce) {
      .md-input ~ label {
        -webkit-transition: none;
        transition: none; } }
  .md-input.is-invalid {
    border-color: #f54394; }
  .md-input.is-valid {
    border-color: #31c971; }
  .md-input.disabled, .md-input[disabled] {
    opacity: 0.5; }

textarea.md-input {
  height: auto; }

.md-input-white:focus, .md-input-white.focus {
  border-color: #fff; }
  .md-input-white:focus ~ label, .md-input-white.focus ~ label {
    color: #fff; }

.md-input-msg {
  position: absolute;
  bottom: 0;
  line-height: 24px;
  font-size: 0.85em; }

.md-input-msg.right {
  right: 0; }

.md-check {
  cursor: pointer;
  padding-left: 1.25rem;
  margin: 0;
  display: inline-block;
  position: relative; }
  .md-check input {
    position: absolute;
    cursor: pointer;
    z-index: 1;
    opacity: 0; }
    .md-check input:checked + i:before {
      border-width: 0;
      background-color: inherit; }
    .md-check input:checked + span .active {
      display: inherit; }
    .md-check input[type="radio"] + i, .md-check input[type="radio"] + i:before {
      border-radius: 50%; }
    .md-check input[type="checkbox"]:checked + i:after {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      position: absolute;
      left: 6px;
      top: 2px;
      display: table;
      width: 6px;
      height: 12px;
      border: 2px solid;
      border-top: 0;
      border-left: 0;
      content: ' '; }
    .md-check input[type="radio"]:checked + i:after {
      position: absolute;
      left: 6px;
      top: 6px;
      display: table;
      width: 6px;
      height: 6px;
      background: #fff;
      border-radius: 50%;
      content: ' '; }
    .md-check input[disabled] + i:before,
    fieldset[disabled] .md-check input + i:before {
      opacity: 0.5; }
    .md-check input[disabled]:checked + i:before {
      opacity: 0.5; }
  .md-check > i {
    width: 0;
    height: 18px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    left: -1.25rem;
    top: -1px; }
    .md-check > i:before {
      content: "";
      position: absolute;
      width: 18px;
      height: 100%;
      border: 2px solid rgba(135, 150, 165, 0.15);
      border-radius: 2px; }
    .md-check > i.no-icon:after {
      display: none !important; }

.md-switch {
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px; }
  .md-switch input {
    position: absolute;
    cursor: pointer;
    width: 36px;
    height: 20px;
    z-index: 1;
    opacity: 0;
    margin-left: -36px; }
    .md-switch input:checked + i:before {
      background: inherit;
      opacity: 0.5; }
    .md-switch input:checked + i:after {
      background: inherit;
      left: 16px; }
    .md-switch input[disabled] + i:before,
    fieldset[disabled] .md-switch input + i:before {
      background-color: rgba(0, 0, 0, 0.12); }
    .md-switch input[disabled] + i:after,
    fieldset[disabled] .md-switch input + i:after {
      background-color: #bdbdbd; }
  .md-switch i {
    width: 0px;
    height: 18px;
    line-height: 1;
    margin-left: -36px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 44px;
    position: relative; }
    .md-switch i:before {
      content: "";
      left: 1px;
      width: 34px;
      top: 3px;
      height: 14px;
      border-radius: 8px;
      position: absolute;
      background-color: #9e9e9e;
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      @media (prefers-reduced-motion: reduce) {
        .md-switch i:before {
          -webkit-transition: none;
          transition: none; } }
    .md-switch i:after {
      content: "";
      position: absolute;
      margin: 0;
      left: 0;
      top: 0;
      outline: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
      -webkit-transition: all 0.2s;
      transition: all 0.2s; }
      @media (prefers-reduced-motion: reduce) {
        .md-switch i:after {
          -webkit-transition: none;
          transition: none; } }

.red {
  background-color: #f44336;
  color: #fff; }

.pink {
  background-color: #e91e63;
  color: #fff; }

.purple {
  background-color: #9c27b0;
  color: #fff; }

.deep-purple {
  background-color: #673ab7;
  color: #fff; }

.indigo {
  background-color: #3f51b5;
  color: #fff; }

.blue {
  background-color: #2196f3;
  color: #fff; }

.light-blue {
  background-color: #03a9f4;
  color: #fff; }

.cyan {
  background-color: #00bcd4;
  color: #fff; }

.teal {
  background-color: #009688;
  color: #fff; }

.green {
  background-color: #4caf50;
  color: #fff; }

.light-green {
  background-color: #8bc34a;
  color: rgba(0, 0, 0, 0.85); }

.lime {
  background-color: #cddc39;
  color: rgba(0, 0, 0, 0.85); }

.yellow {
  background-color: #ffeb3b;
  color: rgba(0, 0, 0, 0.85); }

.amber {
  background-color: #ffc107;
  color: rgba(0, 0, 0, 0.85); }

.orange {
  background-color: #ff9800;
  color: rgba(0, 0, 0, 0.85); }

.deep-orange {
  background-color: #ff5722;
  color: #fff; }

.brown {
  background-color: #795548;
  color: #fff; }

.blue-grey {
  background-color: #607d8b;
  color: #fff; }

.grey {
  background-color: #9e9e9e;
  color: #fff; }

.navbar-laravel {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04); }

:root {
  --litepickerBgColor: #222635 !important;
  --litepickerMonthButton: #9e9e9e;
  --litepickerMonthButtonHover: #2196f3;
  --litepickerWeekdayColor: #9e9e9e;
  --litepickerDayColor: #fff !important;
  --litepickerDayColorHover: #2196f3;
  --litepickerDayIsTodayColor: #f44336;
  --litepickerDayIsInRange: #bbdefb;
  --litepickerDayIsLockedColor: #9e9e9e;
  --litepickerDayIsBookedColor: #9e9e9e;
  --litepickerDayIsStartColor: #fff;
  --litepickerDayIsStartBg: #2196f3;
  --litepickerDayIsEndColor: #fff;
  --litepickerDayIsEndBg: #2196f3;
  --litepickerButtonCancelColor: #fff;
  --litepickerButtonCancelBg: #9e9e9e;
  --litepickerButtonApplyColor: #fff;
  --litepickerButtonApplyBg: #2196f3;
  --litepickerWidthMonth: 400px !important;
  font-size: 1em; }

body.bg-dark {
  color: #ffffff;
  font-family: 'Lato'; }
  body.bg-dark .tabulator-cell:last-of-type {
    width: auto !important; }
  body.bg-dark .weight-100 {
    font-weight: 100; }
  body.bg-dark .weight-200 {
    font-weight: 200; }
  body.bg-dark .weight-400 {
    font-weight: 400; }
  body.bg-dark .weight-500 {
    font-weight: 500; }
  body.bg-dark .weight-600 {
    font-weight: 600; }
  body.bg-dark .weight-700 {
    font-weight: 700; }
  body.bg-dark .weight-800 {
    font-weight: 800; }
  body.bg-dark .weight-900 {
    font-weight: 900; }
  body.bg-dark h1 {
    font-size: 1.7rem; }
  body.bg-dark h2 {
    font-size: 1.6rem; }
  body.bg-dark h3 {
    font-size: 1.5rem; }
  body.bg-dark h4 {
    font-size: 1.4rem; }
  body.bg-dark h5 {
    font-size: 1.3rem; }
  body.bg-dark h6 {
    font-size: 1.2rem; }
  body.bg-dark h1, body.bg-dark .h1, body.bg-dark h2, body.bg-dark .h2, body.bg-dark h3, body.bg-dark .h3, body.bg-dark h4, body.bg-dark .h4, body.bg-dark h5, body.bg-dark .h5, body.bg-dark h6, body.bg-dark .h6, body.bg-dark .text-dark, body.bg-dark .text-color {
    color: inherit; }
  body.bg-dark .btn {
    font-weight: 600;
    padding: 0.5rem 0.7rem;
    font-size: 0.925rem; }
  body.bg-dark #aside {
    background-color: #353949 !important; }
  body.bg-dark .page-header {
    background-color: #292d3a; }
  body.bg-dark .btn-white,
  body.bg-dark .card,
  body.bg-dark .block,
  body.bg-dark .list-group,
  body.bg-dark .ui-switch i:before,
  body.bg-dark .table-theme tbody td {
    color: #ffffff;
    background-color: #353949; }
  body.bg-dark .dropdown-menu {
    color: inherit;
    border-color: transparent;
    background-color: #292e41 !important; }
  body.bg-dark .select2-container--default .select2-selection {
    background-color: #353949; }
  body.bg-dark .bg-light {
    color: #b9c0d3 !important;
    background-color: #222635 !important; }
  body.bg-dark .btn:not([class*="btn-"]),
  body.bg-dark .btn.btn-icon.no-bg {
    color: inherit; }
  body.bg-dark .text-color {
    color: #b9c0d3; }
  body.bg-dark .text-muted,
  body.bg-dark .col-form-label {
    color: #838aa0 !important; }
  body.bg-dark .bg-white,
  body.bg-dark .bg-body {
    background: #222635 !important; }
  body.bg-dark .form-control {
    background-color: transparent; }
  body.bg-dark .input-group-btn .btn {
    background-color: rgba(160, 175, 185, 0.05); }
  body.bg-dark .table-theme tbody td {
    box-shadow: none !important; }
  body.bg-dark .list-item {
    border-top: none !important; }
  body.bg-dark .navbar-brand,
  body.bg-dark .text-highlight {
    color: #fff !important; }
  body.bg-dark .dropdown-divider {
    border-color: #2b3144; }
  body.bg-dark .card.bg-blue {
    background: linear-gradient(45deg, #3b88bf 40%, #2fb0dd 100%); }
  body.bg-dark .card.bg-green {
    background: linear-gradient(45deg, #18afc1 40%, #79b872 100%); }
  body.bg-dark .card.bg-orange {
    background: linear-gradient(45deg, #dd8673 40%, #dcae76 100%); }
  body.bg-dark .card.bg-red {
    background: linear-gradient(45deg, #de4878 40%, #e67792 100%); }
  body.bg-dark .avatar {
    color: rgba(100, 100, 100, 0.5); }
  body.bg-dark .btn-primary {
    background: linear-gradient(45deg, #3b88bf 40%, #2fb0dd 100%); }
  body.bg-dark .btn-green {
    background: linear-gradient(45deg, #18afc1 40%, #79b872 100%);
    color: #fff; }
  body.bg-dark .btn-orange {
    background: linear-gradient(45deg, #dd8673 40%, #dcae76 100%);
    color: #fff; }
  body.bg-dark .btn-red {
    background: linear-gradient(45deg, #de4878 40%, #e67792 100%);
    color: #fff; }
  body.bg-dark .apexcharts-tooltip-text {
    color: #777; }
  body.bg-dark .gd-success {
    background: linear-gradient(45deg, #18afc1 40%, #79b872 100%); }
  body.bg-dark .gd-primary {
    background: linear-gradient(45deg, #3b88bf 40%, #2fb0dd 100%); }
  body.bg-dark .text-blue {
    color: #3b88bf !important; }
  body.bg-dark .text-green {
    color: #79b872; }
  body.bg-dark .text-orange {
    color: #dd8673; }
  body.bg-dark .text-red {
    color: #de4878; }
  body.bg-dark .tabulator.table-dark {
    background-color: #292d3a; }
    body.bg-dark .tabulator.table-dark .tabulator-tableHolder .tabulator-placeholder {
      margin-top: -20px;
      padding: 10px 0; }
      body.bg-dark .tabulator.table-dark .tabulator-tableHolder .tabulator-placeholder span {
        font-style: italic;
        font-weight: 500;
        font-size: 16px;
        color: #fff; }
    body.bg-dark .tabulator.table-dark .tabulator-tableHolder .tabulator-table .tabulator-row.table-dark {
      background-color: #292d3a !important; }
    body.bg-dark .tabulator.table-dark .tabulator-header {
      border-color: #32383e;
      background-color: #292d3a;
      color: #fff; }
      body.bg-dark .tabulator.table-dark .tabulator-header .tabulator-col {
        border-color: #32383e;
        background-color: #292d3a;
        color: #fff; }
        body.bg-dark .tabulator.table-dark .tabulator-header .tabulator-col .tabulator-col-content .tabulator-arrow {
          top: 21px; }
  body.bg-dark .modalLoader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #222635;
    z-index: 99999;
    display: none; }
    body.bg-dark .modalLoader .loadingIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -100px;
      margin-top: -100px;
      z-index: 99999; }
  body.bg-dark .detail-name {
    font-size: 16px; }
  body.bg-dark .modal-dialog {
    z-index: 1050;
    max-height: 85%;
    max-width: 1000px; }
    body.bg-dark .modal-dialog .modal-content {
      background-color: #222635; }
      body.bg-dark .modal-dialog .modal-content .modal-body {
        height: 600px;
        overflow-y: auto; }
    body.bg-dark .modal-dialog .btn-group {
      padding-bottom: 15px; }
  body.bg-dark .modal-backdrop {
    background-color: #0a0a0a; }

#loading {
  background-color: #222635;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99999;
  top: 0;
  text-align: center; }
  #loading .card {
    background-color: #222635; }

#root {
  width: 100%; }

.alert-dismissible {
  padding-left: 0; }
  .alert-dismissible .close {
    padding: 0.59rem 1.25rem; }

.logo img {
  width: 100px; }

.navbar-brand .header-logo img {
  width: 40px; }

.navbar-brand .header-title {
  margin-top: 1rem; }

.bg-dark .formContainer hr {
  border-color: #a0afb9;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: -20px;
  width: 2000px; }

.bg-dark .formContainer label {
  margin-bottom: 0;
  font-size: 15px;
  color: #fff;
  font-weight: 700; }

.bg-dark .formContainer .label {
  font-size: 14px;
  font-weight: 400;
  color: #fff; }

.bg-dark .formContainer h5.form-group {
  font-size: 0.925rem;
  font-weight: 700;
  color: #fff; }

.bg-dark .formContainer .postTitle .title {
  width: 90%; }

.bg-dark .formContainer .accordion > .card {
  border-radius: 0.4rem; }
  .bg-dark .formContainer .accordion > .card > button.btn {
    text-align: left;
    justify-content: left;
    border: 0;
    color: #fff;
    border-bottom: 1px solid rgba(160, 175, 185, 0.15);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .bg-dark .formContainer .accordion > .card > button.btn:hover {
      text-decoration: none; }
    .bg-dark .formContainer .accordion > .card > button.btn span:hover {
      text-decoration: none; }
    .bg-dark .formContainer .accordion > .card > button.btn div.close {
      z-index: 999; }

.bg-dark .formContainer .accordion .card-header {
  border: 0;
  padding-left: 0.5rem;
  width: 100%; }

.bg-dark .formContainer .replyAccordion .card {
  background-color: #292d3a; }

.bg-dark .formContainer .replyAccordion label {
  margin-bottom: 1rem; }
  .bg-dark .formContainer .replyAccordion label.dzu-inputLabel {
    margin-bottom: 0; }

.bg-dark .formContainer .replyAccordion .subtitle {
  font-weight: 900;
  margin-bottom: 20px; }

.bg-dark .formContainer .replyAccordion .form-group {
  padding: 0 15px; }

.bg-dark .formContainer .templateContainer {
  background-color: #222635;
  padding: 15px;
  border-radius: 0.4rem;
  margin: 0 15px;
  margin-bottom: 20px;
  overflow-x: hidden; }
  .bg-dark .formContainer .templateContainer.replied, .bg-dark .formContainer .templateContainer.noQuota {
    margin: 0;
    margin-bottom: 20px; }
  .bg-dark .formContainer .templateContainer.fallback {
    background-color: #353949;
    /*padding-left:0;
                padding-right:0;*/
    margin-left: 0;
    margin-right: 0; }
  .bg-dark .formContainer .templateContainer .accordion {
    padding: 0 15px; }

.bg-dark .formContainer .dzu-dropzone {
  width: 50%;
  min-height: 100px;
  border: 2px dashed #d9d9d9;
  margin: 0;
  margin-bottom: 15px;
  overflow: hidden; }
  .bg-dark .formContainer .dzu-dropzone .dzu-previewImage {
    max-height: 200px;
    max-width: 250px; }
  .bg-dark .formContainer .dzu-dropzone .dzu-previewButton {
    -webkit-filter: invert(1);
            filter: invert(1); }
  .bg-dark .formContainer .dzu-dropzone .dzu-previewFileName {
    color: #fff; }

.bg-dark .formContainer .rdtPicker {
  background-color: #292d3a;
  border: 1px solid #292d3a; }

