.md-switch{
  cursor: pointer;
  padding-left: 36px;
  margin: 0;
  min-height: 20px;
  input{
    position: absolute;
    cursor: pointer;
    width: 36px;
    height: 20px;
    z-index: 1;
    opacity: 0;
    margin-left: -36px;
    &:checked{
      + i{
        &:before{
          background: inherit;
          opacity: 0.5;
        }
        &:after{
          background: inherit;
          left: 16px;
        }
      }
    }

    &[disabled],
    fieldset[disabled] & {
      & + i{
        &:before{
          background-color: rgba(0,0,0,0.12);
        }
        &:after{
          background-color: rgb(189,189,189);
        }
      }
    }
  }
  i{
    width: 0px;
    height: 18px;
    line-height: 1;
    margin-left: -36px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 44px;
    position: relative;
    &:before{
      content: "";
      left: 1px;
      width: 34px;
      top: 3px;
      height: 14px;
      border-radius: 8px;
      position: absolute;
      background-color: rgb(158,158,158);
      @include transition(all 0.2s);
    }
    &:after{
      content: "";
      position: absolute;
      margin: 0;
      left: 0;
      top: 0;
      outline: none;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: #fff;
      box-shadow: 0px 2px 5px 0 rgba(0, 0, 0, 0.26);
      @include transition(all 0.2s);
    }
  }
}
