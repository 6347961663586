/*
Buttons 
*/
.btn{
	font-weight: 500;
	display: -ms-inline-flex;
	display: inline-flex;
	-webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
	-webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
	> i{
		&.float-left,
		&.float-right{
			line-height: inherit;
			margin: 0;
		}
		svg{
			max-width: 16px;
			max-height: 16px;
			vertical-align: middle;
		}
	}
	&.loading{
		text-indent: -9999rem;
	}
	&:hover,
	&:active{
		box-shadow:0 1px 3px 0 rgba(0,0,0,.1);
	}
}

.btn-md{
	padding: 0.75rem 1.25rem;
}

.btn-xs{
	font-size: 0.75rem;
	padding: 0.125rem 0.5rem;
}

.btn-white{
	color: $text-color;
	background-color: #fff;
	border-color: transparent;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05);
	.input-group &{
		border-color: $border-color;
	}
}

.btn-rounded{
	border-radius: 4rem;
	padding-left: 1rem;
	padding-right: 1rem;
	&.btn-xs{
		padding-left: 0.8rem;
		padding-right: 0.8rem;
	}
}

.btn-icon{
	width: 1.375rem + $btn-padding-y*2;
	height: 1.375rem + $btn-padding-y*2;
	padding: 0 !important;
	&.btn-sm{
		width: 1.375rem + $btn-padding-y-sm*2;
		height: 1.375rem + $btn-padding-y-sm*2;
	}
	&.btn-md{
		width: 1.5rem + $btn-padding-y-lg*2;
		height: 1.5rem + $btn-padding-y-lg*2;
	}
	&.btn-lg{
		width: 2.375rem + $btn-padding-y-lg*2;
		height: 2.375rem + $btn-padding-y-lg*2;
	}
}

.btn-line{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: block;
		height: 1px;
		width: 100%;
		background: #000;
		transition: transform .8s cubic-bezier(.9,0,.1,1);
		transform-origin: right center;
		transform: scaleX(0);
	}
	&:hover,
	&:active{
		&:after{
			transform-origin: left center;
			transform: scaleX(1);
		}
	}
}

.btn-wave {
  overflow: hidden;
  position: relative;
  transform: translate3d(0,0,0);
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1.5s;
  }

  &:active:before {
    transform: scale(0,0);
    opacity: .1;
    transition: 0s;
  }
}

.btn-raised{
	transition: box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
	&:not([disabled]):hover,
	&:not([disabled]):focus,
	&:not([disabled]):active{
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
        transform: translate3d(0, -1px, 0); 
	}
}

.btn-group-toggle{
	display: inline-flex;
	padding: 0.25rem 0.125rem;
	border: 1px solid $border-color;
	border-radius: $border-radius;
	.btn{
		font-size: 0.75rem;
		padding: 0.125rem 0.35rem;
		margin: 0 0.125rem !important;
		border-radius: inherit !important;
		&.active{
			color: color-yiq($primary) !important;
			background-color: $primary;
		}
	}
}

.btn-play{
	position: relative;
	&.bg--white{
		color: #6c7781 !important;
	}
	&:before{
		content: '';
		width: 0;
		height: 0;
		position: absolute;
	    top: 50%;
	    left: 50%;
	    transform: translate(-4px, -50%);
	    transform-origin: center center;
		border: 6px solid transparent;
    	border-left-color: currentColor;
    	border-left-width: 10px;
    	transition: all 0.2s;
	}
	&.active:before{
		width: 10px;
	    height: 10px;
	    border-top-width: 0;
	    border-bottom-width: 0;
	    border-left-width: 3px;
	    border-right-width: 3px;
	    border-right-color: currentColor;
	    transform: translate(-50%, -50%) rotate(180.1deg);
	    .is-buffering &{
	    	border: 2px solid;
	    	border-left-color: transparent;
	    	border-radius: 100%;
	    	margin-left: -5px;
	    	margin-top: -5px;
	    	animation: loading 1s linear infinite;
	    }
	}
}
