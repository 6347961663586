/*
Modal 
*/
.modal.fade{
  .modal-left,
  .modal-right,
  .modal-top,
  .modal-bottom {
    position: fixed;
    z-index: 1055;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: 0;
    max-width: 100%;
  }
  .modal-left {
    right: auto !important;
    transform: translate3d(-100%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .modal-right {
    left: auto !important;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .modal-top {
    bottom: auto !important;
    transform: translate3d(0, -100%, 0);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .modal-bottom {
    top: auto !important;
    transform: translate3d(0, 100%, 0);
    transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  &.show{
    .modal-left,
    .modal-right,
    .modal-top,
    .modal-bottom { 
      transform: translate3d(0, 0, 0);
    }
  }
  &.inactive{
    bottom: auto;
    overflow: visible;
  }
}

.modal-open-aside{
  overflow: visible;
}

.modal-header,
.modal-content,
.modal-footer{
  border: none;
}

.modal-dialog > *{
  pointer-events: auto;
}

.modal-header{
  margin-top: -1px;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.modal-footer{
  margin-top: auto;
  border-bottom-left-radius: inherit;
  border-tbottomop-right-radius: inherit;
}

.modal .close{
  float: none;
}
