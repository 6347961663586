@import '~bootstrap/dist/css/bootstrap.css';
@import '../lib/basik/scss/bootstrap';
@import "../lib/basik/scss/theme";
@import '~tabulator-tables/dist/css/bootstrap/tabulator_bootstrap4.min.css';
@import '~simplebar/dist/simplebar.css';
@import '~react-dropzone-uploader/dist/styles.css';
@import '~react-datetime/css/react-datetime.css';
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$bg-dark: #222635;
$aside-dark: #353949;
$nav-dark: #292d3a;

$blue-gradient: linear-gradient(45deg, rgba(59, 136, 191, 1) 40%, rgba(47, 176, 221, 1) 100%);
$blue-gradient-hover: linear-gradient(45deg, rgba(59, 136, 191, 1) 0%, rgba(59, 136, 191, 1) 100%);
$green-gradient: linear-gradient(45deg, rgba(24, 175, 193, 1) 40%, rgba(121, 184, 114, 1) 100%);
$orange-gradient: linear-gradient(45deg, rgba(221, 134, 115, 1) 40%, rgba(220, 174, 118, 1) 100%);
$red-gradient: linear-gradient(45deg, rgba(222, 72, 120, 1) 40%, rgba(230, 119, 146, 1) 100%);

$text-blue: rgba(59, 136, 191, 1);
$text-green: rgba(121, 184, 114, 1);
$text-orange: rgba(221, 134, 115, 1);
$text-red: rgba(222, 72, 120, 1);

.navbar-laravel
{
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
}

:root
{
    --litepickerBgColor: #222635 !important;
    --litepickerMonthButton: #9e9e9e;
    --litepickerMonthButtonHover: #2196f3;
    --litepickerWeekdayColor: #9e9e9e;
    --litepickerDayColor: #fff !important;
    --litepickerDayColorHover: #2196f3;
    --litepickerDayIsTodayColor: #f44336;
    --litepickerDayIsInRange: #bbdefb;
    --litepickerDayIsLockedColor: #9e9e9e;
    --litepickerDayIsBookedColor: #9e9e9e;
    --litepickerDayIsStartColor: #fff;
    --litepickerDayIsStartBg: #2196f3;
    --litepickerDayIsEndColor: #fff;
    --litepickerDayIsEndBg: #2196f3;
    --litepickerButtonCancelColor: #fff;
    --litepickerButtonCancelBg: #9e9e9e;
    --litepickerButtonApplyColor: #fff;
    --litepickerButtonApplyBg: #2196f3;
    --litepickerWidthMonth: 400px !important;
    font-size: 1em;
}

body.bg-dark
{
    color: $white;
    font-family: 'Lato';

    .tabulator-cell
    {
        &:last-of-type
        {
            width: auto !important;
        }
    }

    .weight-100
    {
        font-weight: 100;
    }

    .weight-200
    {
        font-weight: 200;
    }

    .weight-400
    {
        font-weight: 400;
    }

    .weight-500
    {
        font-weight: 500;
    }

    .weight-600
    {
        font-weight: 600;
    }

    .weight-700
    {
        font-weight: 700;
    }

    .weight-800
    {
        font-weight: 800;
    }

    .weight-900
    {
        font-weight: 900;
    }

    h1
    {
        font-size: 1.7rem;
    }

    h2
    {
        font-size: 1.6rem;
    }

    h3
    {
        font-size: 1.5rem;
    }

    h4
    {
        font-size: 1.4rem;
    }

    h5
    {
        font-size: 1.3rem;
    }

    h6
    {
        font-size: 1.2rem;
    }


    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, .text-dark, .text-color
    {
        color: inherit;
    }

    .btn
    {
        font-weight: 600;
        padding: 0.5rem 0.7rem;
        font-size: 0.925rem;
    }

    #aside
    {
        background-color: $aside-dark !important;
    }

    .page-header
    {
        background-color: $nav-dark;
    }

    .btn-white,
    .card,
    .block,
    .list-group,
    .ui-switch i:before,
    .table-theme tbody td
    {
        color: $white;
        background-color: $aside-dark; //lighten($dark, $color-percent);
    }

    .dropdown-menu
    {
        color: inherit;
        border-color: transparent;
        background-color: lighten($dark, $color-percent + 2%) !important;
    }

    .select2-container--default
    {
        .select2-selection
        {
            background-color: $aside-dark;
        }
    }

    .bg-light
    {
        color: $dark-color !important;
        background-color: $bg-dark !important; //darken($dark, $color-percent) !important;
    }

    .btn:not([class*="btn-"]),
    .btn.btn-icon.no-bg
    {
        color: inherit;
    }

    .text-color
    {
        color: $dark-color;
    }

    .text-muted,
    .col-form-label
    {
        color: $dark-muted !important;
    }

    .bg-white,
    .bg-body
    {
        background: $dark !important;
    }

    .form-control
    {
        background-color: transparent;
    }

    .input-group-btn
    {
        .btn
        {
            background-color: $border-color-lt;
        }
    }

    .table-theme tbody td
    {
        box-shadow: none !important;
    }

    .list-item
    {
        border-top: none !important;
    }

    .navbar-brand,
    .text-highlight
    {
        color: #fff !important;
    }

    .card-header,
    .card-footer,
    .b-a,
    .b-l,
    .b-r,
    .b-t,
    .b-b
    {
        //border-color: lighten($dark, $color-percent + 1.5%);
    }

    .dropdown-divider
    {
        border-color: lighten($dark, $color-percent + 3%);
    }

    .card.bg-blue
    {
        background: $blue-gradient;
    }

    .card.bg-green
    {
        background: $green-gradient;
    }

    .card.bg-orange
    {
        background: $orange-gradient;
    }

    .card.bg-red
    {
        background: $red-gradient;
    }

    .avatar
    {
        color: rgba(100, 100, 100, .5);
    }

    .btn-primary
    {
        background: $blue-gradient;
    }

    .btn-green
    {
        background: $green-gradient;
        color: #fff;
    }

    .btn-orange
    {
        background: $orange-gradient;
        color: #fff;
    }

    .btn-red
    {
        background: $red-gradient;
        color: #fff;
    }

    .apexcharts-tooltip-text
    {
        color: #777;
    }

    .gd-success
    {
        background: $green-gradient;
    }

    .gd-primary
    {
        background: $blue-gradient;
    }

    .text-blue
    {
        color: $text-blue !important;
    }

    .text-green
    {
        color: $text-green;
    }

    .text-orange
    {
        color: $text-orange;
    }

    .text-red
    {
        color: $text-red;
    }

    .tabulator.table-dark
    {
        background-color: #292d3a;

        .tabulator-tableHolder
        {
            .tabulator-placeholder
            {
                margin-top: -20px;
                padding: 10px 0;

                span
                {
                    font-style: italic;
                    font-weight: 500;
                    font-size: 16px;
                    color: #fff;
                }
            }

            .tabulator-table
            {
                .tabulator-row.table-dark
                {
                    background-color: #292d3a !important;
                }
            }
        }

        .tabulator-header
        {
            border-color: #32383e;
            background-color: #292d3a;
            color: #fff;

            .tabulator-col
            {
                border-color: #32383e;
                background-color: #292d3a;
                color: #fff;

                .tabulator-col-content
                {
                    .tabulator-arrow
                    {
                        top: 21px;
                    }
                }

            }
        }
    }

    .modalLoader
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #222635;
        z-index: 99999;
        display: none;

        .loadingIcon
        {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -100px;
            margin-top: -100px;
            z-index: 99999;
        }
    }

    .detail-name
    {
        font-size: 16px;
    }

    .modal-dialog
    {
        z-index: 1050;
        max-height: 85%;
        max-width: 1000px;

        .modal-content
        {
            background-color: #222635;

            .modal-body
            {
                height: 600px;
                overflow-y: auto;
            }
        }

        .btn-group
        {
            padding-bottom: 15px;
        }
    }

    .modal-backdrop
    {
        background-color: #0a0a0a;
    }
}

#loading
{
    background-color: #222635;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 99999;
    top: 0;
    text-align: center;

    .card
    {
        background-color: #222635;
    }
}

#root
{
    width: 100%;
}

.alert-dismissible
{
    padding-left: 0;

    .close
    {
        padding: 0.59rem 1.25rem;
    }
}

.logo
{
    img
    {
        width: 100px;
    }
}

.navbar-brand
{
    .header-logo
    {
        img
        {
            width: 40px;

        }
    }

    .header-title
    {
        margin-top: 1rem;
    }
}

@import './FormContainer';
